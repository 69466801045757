<template>
  <nav class="fixed z-50 w-full h-14 lg:hidden bg-paleBlue">
    <div class="w-full px-2 bg-paleBlue sm:px-4 xl:hidden lg:px-4 xl:py-2">
      <div class="flex justify-between h-14">
        <div class="flex w-full px-2 lg:px-0">
          <div class="flex items-center shrink-0 px-0">
            <img class="block w-auto h-6 md:h-8" :src="gt_logo" alt="GT" />
          </div>
          <div v-if="participantDetails" class="flex items-center justify-evenly">
            <h3 class="ml-4 font-normal text-darkBlue">
              {{`${participantDetails.firstName} ${participantDetails.lastName} - ${slide}`}}
            </h3>
          </div>
          <div class="flex items-center ml-auto">
            <!-- Mobile menu button -->
            <a @click="toggleNav()" class="inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" >
              <Bars3Icon v-if="!open" class="w-8 h-8 text-gtLogoColor" aria-hidden="true" />
              <XMarkIcon v-else class="w-8 h-8 text-gtLogoColor" aria-hidden="true" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <div id="parent" :class="open ? 'open' : ''">
    <!-- Narrow sidebar-->
    <nav id="nav" class="fixed z-40 shrink-0 block w-full h-screen overflow-y-auto lg:w-96 sm:w-60 bg-paleBlue">
      <div class="h-12 lg:hidden"></div>
      <div :class="!hideButtonsForEnrollee && 'pt-4'" class="relative flex flex-col space-y-2 w-full lg:w-96 sm:w-60">
        <div class="m-10" v-show="controlsOnly">
          <img class="w-auto h-20" :src="gt_logo" alt="Workflow" />
        </div>
        <div class="flex items-center" v-show="!hideButtonsForEnrollee && !controlsOnly">
          <div class="flex items-center ml-3">
            <!-- Heroicon name: solid/chevron-left -->
            <svg class="shrink-0 w-3 h-3 text-gtBlue800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
            <a href="javascript:void(0)" @click="navigateToParticipantListing" class="ml-1 text-gtBlue800 hover:text-gray-700">Back to Hub</a>
          </div>
        </div>
        <div v-show="!controlsOnly" id="heroSection" :class="hideButtonsForEnrollee && 'full-height'" class=" overflow-y-auto lg:pt-2">
          <!-- Demographics Details -->
          <div class="pt-0 pb-1 space-y-0">
            <a href="javascript:void(0)" @click="navigate(enrolleeRole)" class="text-gtGray900 block px-4 py-1.5">{{ demographicDetails.title }}</a>
            <div
              :class="!demographicDetail.isCompleted && !demographicDetail.active ? 'ml-8' : 'ml-4'"
              class="flex items-center"
              v-for="demographicDetail in demographicDetails.data"
              :key="demographicDetail"
            >
              <span v-if="demographicDetail.isCompleted">
                <svg xmlns="http://www.w3.org/2000/svg" :class="demographicDetail.isCompleted ? 'visible' : 'invisible'" class="w-5 h-5 text-gtGreen600" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
              </span>
              <span v-if="demographicDetail.active">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
              </span>
              <a href="javascript:void(0)" @click="navigate(demographicDetail.route)" :class="demographicDetail.isCompleted ? 'text-gtGreen600' : 'text-gray-500'" class="block py-1 pl-3 pr-4 ">{{ demographicDetail.name }}</a>
            </div>
          </div>

          <!-- Federal and State Forms -->
          <div class="pt-0 pb-1 space-y-0" v-if="fullPacket.federalDocuments?.length">
            <a href="javascript:void(0)" @click="navigate('E-Sign1', currentEnrollee.enrolleeEnrollmentProgress?.federalFormsComplete)" class="  text-gtGray900 block px-4 py-1.5">Federal Forms</a>
            <div class="flex items-center ml-8" v-for="federalForm in fullPacket.federalDocuments" :key="federalForm.id">
              <a href="javascript:void(0)" @click="navigateToAcknowledgment('FederalAcknowledgment', federalForm)" class="block py-1 pl-3 pr-4 text-gray-400 border-l-4 border-transparent hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 ">{{
                federalForm.document.title
              }}</a>
            </div>
          </div>

          <!-- State Forms -->
          <div class="pt-0 pb-1 space-y-0" v-if="fullPacket.stateDocuments?.length">
            <a href="javascript:void(0)" @click="navigate('E-Sign4', currentEnrollee.enrolleeEnrollmentProgress?.stateFormsComplete)" class="  text-gtGray900 block px-4 py-1.5">State Forms</a>
            <div class="flex items-center ml-8" v-for="stateForm in fullPacket.stateDocuments" :key="stateForm.id">
              <a href="javascript:void(0)" @click="navigateToAcknowledgment('StateAcknowledgment', stateForm)" class="block py-1 pl-3 pr-4 text-gray-400 border-l-4 border-transparent hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 ">{{
                stateForm.document.title
              }}</a>
            </div>
          </div>

          <!-- Program Documents -->
          <div class="pt-0 pb-1 space-y-0" v-if="fullPacket.programDocuments?.length">
            <a href="javascript:void(0)" @click="navigate('E-Sign5', currentEnrollee.enrolleeEnrollmentProgress?.programFormsComplete)" class="  text-gtGray900 block px-4 py-1.5">Program Documents</a>
            <div class="flex items-center ml-8" v-for="programDocument in fullPacket.programDocuments" :key="programDocument.id">
              <a href="javascript:void(0)" @click="navigateToAcknowledgment('ProgramAcknowledgment', programDocument)" class="block py-1 pl-3 pr-4 text-gray-400 border-l-4 border-transparent hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 ">{{
                programDocument.document.title
              }}</a>
            </div>
          </div>

          <!-- Miscellaneous Documents -->
          <div class="pt-0 pb-1 space-y-0" v-if="fullPacket.miscellaneousDocuments?.length">
            <a href="javascript:void(0)" @click="navigate('E-Sign6', currentEnrollee.enrolleeEnrollmentProgress?.miscDocsComplete)" class="  text-gtGray900 block px-4 py-1.5">Miscellaneous Documents</a>
            <div class="flex items-center ml-8" v-for="miscDoc in fullPacket.miscellaneousDocuments" :key="miscDoc.id">
              <a href="javascript:void(0)" @click="navigateToAcknowledgment('MiscAcknowledgment', miscDoc)" class="block py-1 pl-3 pr-4 text-gray-400 border-l-4 border-transparent hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 ">{{
                miscDoc.document.displayText || miscDoc.document.title
              }}</a>
            </div>
          </div>

          <!-- Document Image Capture -->
          <div class="pt-0 pb-1 space-y-0">
            <a href="javascript:void(0)" @click="navigate('E-Sign7', currentEnrollee.enrolleeEnrollmentProgress?.uploadDocsComplete)" class="  text-gtGray900 block px-4 py-1.5">Document Image Capture</a>
            <div class="flex items-center ml-8" v-for="imageDoc in fullPacket.imageCaptureDocuments" :key="imageDoc.id">
              <a href="javascript:void(0)" @click="navigate('ImageCapture')" class="block py-1 pl-3 pr-4 text-gray-400 border-l-4 border-transparent hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 ">{{
                imageDoc.document.displayText || imageDoc.document.title
              }}</a>
            </div>
          </div>

          <!-- E-Sign-->
          <div class="pt-0 pb-1 space-y-0">
            <a href="javascript:void(0)" @click="navigate('E-Sign8', currentEnrollee.enrolleeEnrollmentProgress?.esignComplete)" class="  text-gtGray900 block px-4 py-1.5">{{ eSigns.title }}</a>
            <div class="flex items-center ml-8" v-for="eSign in eSigns.data" :key="eSign">
              <a href="javascript:void(0)" class="block py-1 pl-3 pr-4 text-gray-400 border-l-4 border-transparent hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 ">{{
                eSign
              }}</a>
            </div>
          </div>
        </div>
      </div>
      <!-- Footer -->
      <div id="footer" class="w-full lg:w-96 sm:w-60 bg-paleBlue">
        <div class="flex flex-row w-full mt-1 text-gtGray700 px-4" v-if="!isProd">
          Language: <LanguageSwitcher class="mx-4"></LanguageSwitcher>
        </div>
        <div class="flex flex-col w-full mt-1" v-if="!hideButtonsForEnrollee">
          <a href="javascript:void(0)" @click="goToEnrollmentDetails('add_caregiver')" class="w-full block px-4 py-2 lg:py-2.5   text-gtGray700 hover:text-gray-800 hover:bg-gtBlue100">Add a Caregiver</a>
          <a href="javascript:void(0)" @click="goToEnrollmentDetails('')" class="w-full block px-4 py-2 lg:py-2.5   text-gtGray700 hover:text-gray-800 hover:bg-gtBlue100">Configure Packet</a>
          <a href="javascript:void(0)" @click="goToEnrollmentDetails('')" class="w-full block px-4 py-2 lg:py-2.5   text-gtGray700 hover:text-gray-800 hover:bg-gtBlue100">Add a Note</a>
          <a href="javascript:void(0)" @click="goToProfile" class="w-full block px-4 py-2 lg:py-2.5   text-gtGray700 hover:text-gray-800 hover:bg-gtBlue100">
            <span class="inline-flex">{{ currentUser.fullName }}</span>
            <span class="inline-flex float-right">
              <svg class="ml-auto transition-all duration-200 transform w-7 color"
                fill="none"
                stroke="#3C7EC6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                aria-hidden="true"
                >
                <g id="Chevron_Down_Circle">
                  <path d="M16,0C7.164,0,0,7.163,0,16c0,8.836,7.164,16,16,16c8.837,0,16-7.164,16-16C32,7.163,24.837,0,16,0z M16,30   C8.28,30,2,23.72,2,16C2,8.28,8.28,2,16,2c7.72,0,14.031,6.28,14.031,14C30.031,23.72,23.72,30,16,30z" fill="#3C7EC6"/>
                  <path d="M22.3,12.393l-6.285,6.195l-6.285-6.196c-0.394-0.391-1.034-0.391-1.429,0   c-0.394,0.391-0.394,1.024,0,1.414l6.999,6.9c0.384,0.38,1.044,0.381,1.429,0l6.999-6.899c0.394-0.391,0.394-1.024,0-1.414   C23.334,12.003,22.695,12.003,22.3,12.393z" fill="#3C7EC6"/>
                </g>
              </svg>
            </span>
          </a>
          <button @click="showCompleteEnrollmentModal" class="mt-5 mx-2 py-1.5 px-2 lg:px-20 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite">
            Exit Enrollment
          </button>
        </div>
      </div>
    </nav>

    <div id="block" class="hidden sm:block lg:hidden"></div>
  </div>
  <div class="text-center" v-if="showEnrollmentCompletedModal">
    <EnrollmentCompleteModal @closeModal="closeEnrollmentCompleteModal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import gtLogo from "Src/assets/images/gt_icon.png";
import userService from "Src/services/backend/user"
import EnrollmentCompleteModal from "Views/EnrollmentPresentation/TemplateInstances/enrollment_complete_modal";
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline';
import {ENV} from "@/config";

export default {
  name: 'Nav',
  props: ['participantDetails', 'slide', 'id', 'controlsOnly'],
  components: {
    EnrollmentCompleteModal,
    Bars3Icon,
    XMarkIcon
  },
  computed: {
    ...mapGetters('enrollmentModule', ['getFullPacket', 'getEnrollees']),
    enrolleeRole() {
      switch (this.currentEnrollee.type) {
        case 1: return 'ParticipantInformation1';
        case 2: return 'CaregiverInformation1';
        case 3: return 'VendorInformation1';
        default: return 'Welcome-1';
      }
    },
    isProd() {
      return ENV === 'production';
    },
  },
  async mounted() {
    if (this.$store.getters.getLoggedInThroughJwtToken) {
      this.hideButtonsForEnrollee = this.$store.getters.getEnrolleeRoleId == 5;
    } else {
      const user = await userService.getUserInfo()
      this.currentUser = user.data[0]
    }
  },
  data() {
    return {
      open: (window.sessionStorage.getItem("sideNavOpen") == true || window.sessionStorage.getItem("sideNavOpen") == 'true'),
      gt_logo: gtLogo,
      fullPacket: {
        federalDocuments: [],
        stateDocuments: [],
        programDocuments: [],
        imageCaptureDocuments: [],
        miscellaneousDocuments: [],
      },
      demographicDetails: {
        title: 'Demographic Details',
        data: [],
      },
      documentImageCaptures: {
        title: 'Document Image Captures',
        data: [],
      },
      miscellaneousDocuments: {
        title: 'Miscellaneous Documents',
        data: [],
      },
      eSigns: {
        title: 'E-Sign',
        data: [],
      },
      hideButtonsForEnrollee: false,
      currentUser: {
        fullName: ''
      },
      showEnrollmentCompletedModal: false,
      currentEnrollee: ''
    };
  },
  methods: {
    toggleNav() {
      this.open = !this.open;
      window.sessionStorage.setItem("sideNavOpen", this.open);
    },
    navigate(value, validCheck = true) {
      if (!validCheck) return;
      this.$router.push({ name: value, params: { enrollmentId: this.id, enrolleeId: this.currentEnrollee.id, enrolleeType: this.currentEnrollee.type }})
    },
    navigateToAcknowledgment(value, document) {
      if (document.document.isAcknowledged)
        this.$router.push({ name: value, params: { enrollmentId: this.id, enrolleeId: this.currentEnrollee.id, enrolleeType: this.currentEnrollee.type, documentId: document.documentId }})
    },
    navigateToParticipantListing() {
      this.$router.push({ name: 'ParticipantLandingSlide', params: { enrollmentId: this.id } });
    },
    goToEnrollmentDetails(query) {
      this.$router.push({ name: 'EnrollmentsDetails', params: { id: this.id }, query: {tab: query} });
    },
    goToProfile() {
      this.$router.push({ name: 'EditUserDetail', params: { id: this.currentUser.id}})
    },
    showCompleteEnrollmentModal() {
      this.showEnrollmentCompletedModal = true;
    },
    closeEnrollmentCompleteModal() {
      this.showEnrollmentCompletedModal = false;
    },
  },
  watch: {
    getFullPacket(newPacket) {
       if (newPacket) {
       this.fullPacket = newPacket;
       }
    },
    getEnrollees(newEnrollee) {
      if (newEnrollee?.participant) {
        this.currentEnrollee = newEnrollee.participant;
        this.demographicDetails.data.push(
          {
            isCompleted: false,
            active: false,
            name: 'Participant Information',
            route: 'ParticipantInformation1',
          },
          {
            isCompleted: false,
            active: false,
            name: 'EORInformation',
            route: 'EORInformation',
          },
          {
            isCompleted: false,
            active: false,
            name: 'RepresentativeInformation',
            route: 'RepresentativeInformation',
          },
          // {
          //   isCompleted: false,
          //   active: false,
          //   name: 'Case Manager and Authorization',
          // },
        );
      } else if (newEnrollee?.caregiver) {
        this.currentEnrollee = newEnrollee.caregiver;
        this.demographicDetails.data.push(
          {
            isCompleted: false,
            active: false,
            name: 'Caregiver Information 1',
            route: 'CaregiverInformation1',
          },
          {
            isCompleted: false,
            active: false,
            name: 'Caregiver Information 2',
            route: 'CaregiverInformation2',
          },
          {
            isCompleted: false,
            active: false,
            name: 'Caregiver Information 3',
            route: 'CaregiverInformation3',
          },
          {
            isCompleted: false,
            active: false,
            name: 'Caregiver Information 4',
            route: 'CaregiverInformation4',
          },
          {
            isCompleted: false,
            active: false,
            name: 'Caregiver Information 5',
            route: 'CaregiverInformation5',
          },
          {
            isCompleted: false,
            active: false,
            name: 'Caregiver Information 6',
            route: 'CaregiverInformation6',
          },
          {
            isCompleted: false,
            active: false,
            name: 'Caregiver I9 Citizenship Status',
            route: 'CaregiverI9EligibilityToWork',
          },
          {
            isCompleted: false,
            active: false,
            name: 'Caregiver I9 Identification',
            route: 'CaregiverI9Identification',
          },
          {
            isCompleted: false,
            active: false,
            name: 'Caregiver W4 Information',
            route: 'CaregiverW4Information',
          },
          {
            isCompleted: false,
            active: false,
            name: 'Caregiver Preferred Payment',
            route: 'CaregiverPreferredPayment',
          },
        );
      } else if (newEnrollee?.vendor) {
        this.currentEnrollee = newEnrollee.vendor;
        this.demographicDetails.data.push(
          {
            isCompleted: false,
            active: false,
            name: 'Vendor Information 1',
            route: 'VendorInformation1',
          },
          {
            isCompleted: false,
            active: false,
            name: 'Vendor Information 2',
            route: 'VendorInformation2',
          },
        );
      }
    },
  },
};
</script>

<style scoped>
#heroSection {
  height: calc(100vh - 20rem - 3rem - 1rem);
  padding-bottom: 1em;
}

#heroSection.full-height {
  height: calc(100vh - 3rem - 1rem);
}

#footer {
  height: 16.5rem;
  position: fixed;
  bottom: 0;
  padding: 8px 0;
}

#parent>* {
  transition: ease-out 300ms;
}

#parent>#block {
  width: 0;
}

#parent.open>#block {
  width: 15rem;
}

#parent>#nav {
  transform: translateX(0)
}

#parent.open>#nav {
  transform: translateX(-100%);
} 

@media (min-width: 640px) {
    #parent.open>#nav {
    transform: translateX(0);
  } 
}

@media (min-width: 1024px) {
  #heroSection {
    height: calc(100vh - 20rem);
  }
  #heroSection.full-height {
    margin-top: 0;
    height: calc(100vh);
  }
  #parent>#block, #parent.open>#block {
    width: 24rem;
  }

  #parent {
    transform: translateX(calc(-100vw + 100%));
  }
}
</style>
