.
<template>
  <div class="w-full min-h-screen overflow-x-hidden overflow-y-auto bg-white" :class="showSpinner ? 'opacity-25' : ''">
    <div class="flex flex-1 h-screen">
      <main class="flex-1 h-full min-w-0 px-4 pt-4 overflow-x-hidden overflow-y-auto sm:pt-12 2xl:pt-2 lg:px-16 2xl:px-60 lg:flex"><div class="hidden lg:block w-96"></div>
        <section aria-labelledby="primary-heading" class="flex flex-col flex-1 h-full min-w-0 overflow-x-visible pt-14 lg:pt-0 lg:order-last">
          <ParticipantInfo>
            <template v-slot:header>
              <h1 class="px-4 mt-6 text-3xl text-darkBlue lg:mt-16">
                We need to collect some information to verify your eligibility to work in the US.
              </h1>
            </template>

            <template v-slot:description>
              <h3 class="px-4 mt-4 mb-5 text-base font-normal text-darkBlue md:text-xl">
                Federal law requires that employers verify your identity and your eligibility to work in the United States. In addition, they must collect documents to verify this information.
              </h3>
              <h3 class="px-4 mb-5 text-base font-normal text-darkBlue md:text-xl">
                Please select which documents you would like to provide to verify this information. This information will be used to complete your Federal I-9 form. At the end of the enrollment, you will need to upload copies of the documents you select.
              </h3>
              <h3 class="px-4 mb-10 text-base font-normal text-darkBlue md:text-xl">
                Your employer, {{ participantGivenCare }}, will need to review these documents.
              </h3>
            </template>

            <template v-slot:content>
              <CaregiverI9IdentificationContent :caregiverData="caregiveri9id" @save="proceedToNext" @back="returnToPrevious" />
            </template>
          </ParticipantInfo>
        </section>
      </main>
      <AppSideNavMenu :participantDetails="caregiveri9id" :slide="slideName" :id="enrollmentId"/>
    </div>
  </div>
  <GenesysChat></GenesysChat>

  <!-- Spinner -->
  <div class="fixed flex justify-center my-3 text-gray-500 left-1/2 top-1/2" v-if="showSpinner">
    <svg class="h-8 mr-3 -ml-1 animate-spin w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
</template>

<script>
import ParticipantInfo from 'Views/EnrollmentPresentation/StaticScreens/participant_info.vue';
import CaregiverI9IdentificationContent
  from 'Views/EnrollmentPresentation/TemplateInstances/caregiver_i9Identification_content.vue';
import {mapActions, mapGetters} from 'vuex';
import enrollmentService from 'Services/backend/enrollments';
import edpService from '../../Enrollments/EnrollmentDetails/service';
import GenesysChat from "@/views/Shared/GenesysChat.vue";

export default {
  name: 'CaregiverI9Identification',
  mounted() {
    this.enrollmentId = this.$route.params.enrollmentId;
    this.enrolleeId = this.$route.params.enrolleeId;
    this.enrolleeType = this.$route.params.enrolleeType;
  },
  components: {
    GenesysChat,
    ParticipantInfo,
    CaregiverI9IdentificationContent,
  },
  data() {
    return {
      caregiveri9id: {
        step: 8,
        listADocumentTitle: '',
        listAIssuingAuthority: '',
        listADocumentNumber: '',
        listAExpirationDate: '',
        listBDocumentTitle: '',
        listBIssuingAuthority: '',
        listBDocumentNumber: '',
        listBExpirationDate: '',
        listCDocumentTitle: '',
        listCIssuingAuthority: '',
        listCDocumentNumber: '',
        listCExpirationDate: '',
        ssn: '',
        driversLicenseId: '',
      },

      enrollmentId: '',
      slideName: 'Caregiver I9 Identification',
      showSpinner: true,
      enrolleeType: '',
      participantGivenCare: ''
    };
  },
  methods: {
    returnToPrevious() {
      if (this.showSpinner) return;
      this.$router.push({ name: 'CaregiverI9EligibilityToWork', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
    },
    proceedToNext(data) {
      if (this.showSpinner) return;
      const body = {
        type: 'CAREGIVER',
        caregiver: data,
      };
      enrollmentService.updateEnrolleeDetails(this.enrolleeId, body).then(
        (response) => {
          edpService.processEnrolleePresentationSavedResponse(response, this.$toast.bind(this));
          this.$router.push({ name: 'CaregiverW4Information', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
        },
        (error) => {
          edpService.processEnrolleePresentationSavedResponse(error, this.$toast.bind(this));
          console.error(error);
        },
      );
    },
    ...mapActions('enrollmentModule', ['loadEnrollees']),
  },

  computed: {
    ...mapGetters('enrollmentModule', ['getEnrollees']),
  },

  watch: {
    getEnrollees(newEnrollees) {
      if (newEnrollees) {
        this.showSpinner = false;
        this.caregiveri9id = {
          step: 8,
          listADocumentTitle: newEnrollees?.caregiver?.caregiverDetails?.listADocumentTitle,
          listAIssuingAuthority: newEnrollees?.caregiver?.caregiverDetails?.listAIssuingAuthority,
          listADocumentNumber: newEnrollees?.caregiver?.caregiverDetails?.listADocumentNumber,
          listAExpirationDate: newEnrollees?.caregiver?.caregiverDetails?.listAExpirationDate,
          listBDocumentTitle: newEnrollees?.caregiver?.caregiverDetails?.listBDocumentTitle,
          listBIssuingAuthority: newEnrollees?.caregiver?.caregiverDetails?.listBIssuingAuthority,
          listBDocumentNumber: newEnrollees?.caregiver?.caregiverDetails?.listBDocumentNumber,
          listBExpirationDate: newEnrollees?.caregiver?.caregiverDetails?.listBExpirationDate,
          listCDocumentTitle: newEnrollees?.caregiver?.caregiverDetails?.listCDocumentTitle,
          listCIssuingAuthority: newEnrollees?.caregiver?.caregiverDetails?.listCIssuingAuthority,
          listCDocumentNumber: newEnrollees?.caregiver?.caregiverDetails?.listCDocumentNumber,
          listCExpirationDate: newEnrollees?.caregiver?.caregiverDetails?.listCExpirationDate,
          ssn: newEnrollees?.caregiver?.socialSecurityNumber,
          driversLicenseId: newEnrollees?.caregiver?.caregiverDetails?.driverLicenseId,
        };
        this.participantGivenCare = `${newEnrollees?.caregiver?.participant?.firstName} ${newEnrollees?.caregiver?.participant?.lastName}`
      }
    },
  },

  async created() {
    this.enrolleeId = this.$route.params.enrolleeId;
    await this.loadEnrollees(this.$route.params.enrolleeId);
  },
};
</script>

<style></style>
