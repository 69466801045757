import { store } from '@/store';
import { USER_ROLES } from './../config';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { BASE_URL, ENV } from '@/config';
import authService from '../services/backend/auth';
import SignIn from 'Views/Login/SignIn.vue';
import Users from 'Views/Users/UserList/UserListingTable.vue';
import UserDetail from 'Views/Users/UserDetails/UserDetail.vue';
import PacketListing from 'Views/Packets/PacketList/PacketListing.vue';
import PacketDetails from 'Views/Packets/PacketDetails/PacketDetails.vue';
import Enrollments from 'Views/Enrollments/EnrollmentList/Enrollments.vue';
import MyEnrollments from 'Views/Enrollments/EnrollmentList/MyEnrollments.vue';
import EnrollmentsDetails from 'Views/Enrollments/EnrollmentDetails/EnrollmentsDetails.vue';
import UploadDocuments from 'Views/Enrollments/EnrollmentDetails/UploadDocuments.vue';
import AddDocument from 'Views/Documents/DocumentDetails/DocumentDetails.vue';
import Documents from 'Views/Documents/DocumentList/DocumentListing.vue';
import NotFound from 'Views/Shared/NotFound.vue';
import Error from 'Views/Shared/Error.vue';
import FirstDetailSlide from 'Views/EnrollmentPresentation/TemplateSlides/first_details_slide.vue';
import ParticipantLanding from 'Views/EnrollmentPresentation/TemplateSlides/participant_landing_slide.vue';
import Schedule from 'Views/Schedule/ScheduleDetails/ScheduleDetails.vue';
import ParticipantInfo1 from 'Views/EnrollmentPresentation/TemplateSlides/participant_info1_slide.vue';
import ParticipantInfo2 from 'Views/EnrollmentPresentation/TemplateSlides/participant_info2_slide.vue';
import ParticipantInfo3 from 'Views/EnrollmentPresentation/TemplateSlides/participant_info3_slide.vue';
import EORInfo from 'Views/EnrollmentPresentation/TemplateSlides/eor_info_slide.vue';
import RepresentativeInfo from 'Views/EnrollmentPresentation/TemplateSlides/representative_info_slide.vue';
import ScheduleParticipantList from 'Views/Schedule/ScheduleDetails/ScheduleParticipantList.vue';
import ParticipantWelcome1 from 'Views/EnrollmentPresentation/TemplateSlides/welcome1_slide.vue';
import ParticipantWelcome2 from 'Views/EnrollmentPresentation/TemplateSlides/welcome2_slide.vue';
import ParticipantWelcome3 from 'Views/EnrollmentPresentation/TemplateSlides/welcome3_slide.vue';
import ParticipantWelcome4 from 'Views/EnrollmentPresentation/TemplateSlides/welcome4_slide.vue';
import ESign1 from 'Views/EnrollmentPresentation/TemplateSlides/e-sign1_slide.vue';
import ESign4 from 'Views/EnrollmentPresentation/TemplateSlides/e-sign4_slide.vue';
import ESign5 from 'Views/EnrollmentPresentation/TemplateSlides/e-sign5_slide.vue';
import ESign6 from 'Views/EnrollmentPresentation/TemplateSlides/e-sign6_slide.vue';
import ESign7 from 'Views/EnrollmentPresentation/TemplateSlides/e-sign7_slide.vue';
import ESign8 from 'Views/EnrollmentPresentation/TemplateSlides/e-sign8_slide.vue';
import Summary from 'Views/EnrollmentPresentation/TemplateSlides/summary_slide.vue';
import FinalSteps from 'Views/EnrollmentPresentation/TemplateSlides/final_steps_slide.vue';
import CaregiverInfo1 from 'Views/EnrollmentPresentation/TemplateSlides/caregiver_info1_slide.vue';
import CaregiverInfo2 from 'Views/EnrollmentPresentation/TemplateSlides/caregiver_info2_slide.vue';
import CaregiverInfo3 from 'Views/EnrollmentPresentation/TemplateSlides/caregiver_info3_slide.vue';
import CaregiverInfo4 from 'Views/EnrollmentPresentation/TemplateSlides/caregiver_info4_slide.vue';
import CaregiverInfo5 from 'Views/EnrollmentPresentation/TemplateSlides/caregiver_info5_slide.vue';
import CaregiverInfo6 from 'Views/EnrollmentPresentation/TemplateSlides/caregiver_info6_slide.vue';
import CaregiverI9EligibilityToWork from 'Views/EnrollmentPresentation/TemplateSlides/caregiver_i9EligibilityToWork_slide.vue';
import CaregiverI9Identification from 'Views/EnrollmentPresentation/TemplateSlides/caregiver_i9Identification_slide.vue';
import CaregiverPreferredPayment from 'Views/EnrollmentPresentation/TemplateSlides/caregiver_preferred_payment_slide.vue';
import CaregiverW4Info from 'Views/EnrollmentPresentation/TemplateSlides/caregiver_w4info_slide.vue';
import FederalAcknowledgment from 'Views/EnrollmentPresentation/TemplateSlides/federalForm_acknowledgment_slide.vue';
import FederalCustomFields from 'Views/EnrollmentPresentation/TemplateSlides/federalForm_customFields_slide.vue';
import StateAcknowledgment from 'Views/EnrollmentPresentation/TemplateSlides/stateForm_acknowledgment_slide.vue';
import StateCustomFields from 'Views/EnrollmentPresentation/TemplateSlides/stateForm_customFields_slide.vue';
import ProgramAcknowledgment from 'Views/EnrollmentPresentation/TemplateSlides/programForm_acknowledgment_slide.vue';
import ProgramCustomFields from 'Views/EnrollmentPresentation/TemplateSlides/programForm_customFields_slide.vue';
import IDCapture from 'Views/EnrollmentPresentation/TemplateSlides/caregiver_id_capture_slide.vue';
import ImageCapture from 'Views/EnrollmentPresentation/TemplateSlides/participant_image_capture_slide.vue';
import EnrolleeAuthPage from 'Views/EnrollmentPresentation/EnrolleeAuthPage.vue';
import VendorInfo1 from 'Views/EnrollmentPresentation/TemplateSlides/vendor_info1_slide.vue';
import VendorInfo2 from 'Views/EnrollmentPresentation/TemplateSlides/vendor_info2_slide.vue';
import MiscAcknowledgment from 'Views/EnrollmentPresentation/TemplateSlides/miscForm_acknowledgment_slide.vue';
import MiscCustomFields from 'Views/EnrollmentPresentation/TemplateSlides/miscForm_customFields_slide.vue';
import Confirmation from 'Views/Confirmation/Confirmation.vue'
import userFunctions from '../utilities/userFunctions';


const routes: Array<RouteRecordRaw> = [

  //* UNPROTECTED ROUTES *//
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn,
    meta: {
      title: 'GT | Sign In',
      requiresAuth : false
    },
  },
  {
    path: '/enrollee/auth',
    name: 'EnrolleeAuthPage',
    component: EnrolleeAuthPage,
    meta: {
      title: 'GT | SelfService',
      requiresAuth : false      
    }
  },
  {
    path: '/confirm/',
    name: 'Confirm',
    component: Confirmation,
    meta: {
      title: 'GT | Confirm',
      requiresAuth: false
    },
  },
  //* PROTECTED ROUTES START HERE *//

  //entry point of the app after azure login.
  {
    path: '/',
    component: MyEnrollments,
    //check if the user is logged in. This is the entry
    //point of the app after signin. 
    beforeEnter: (to, from, next) => {
      authService.userCheck()
      .then(() => {
        next(localStorage.getItem('pathToLoadAfterLogin') || '/my-enrollments')
      },
      () =>{
        next('/signin')
      })
    },
    meta: {
      title: 'GT | My Enrollments'
    },
  },  


  // users routes
  {
    path: '/users',
    name: 'UsersList',
    component: Users,
    meta: {
      requiresAuth: true,
      title: 'GT | Users',
      admin : true,
      fsr : false,
      schedular : false
    },
  },
  {
    path: '/users/new',
    name: 'NewUser',
    component: UserDetail,
    meta: {
      requiresAuth: true,
      title: 'GT | User Details',
      admin : true,
      fsr : false,
      schedular : false
    },
  },
  {
    path: '/users/:id',
    name: 'EditUserDetail',
    component: UserDetail,
    meta: {
      requiresAuth: true,
      title: 'GT | User Information',
      admin : true,
      fsr : false,
      schedular : false
    },
  },

  // packets routes
  {
    path: '/packets',
    name: 'PacketListing',
    component: PacketListing,
    meta: {
      requiresAuth: true,
      title: 'GT | Packets',
      admin : true,
      fsr : false,
      schedular : false
    },
  },
  {
    path: '/packets/:id',
    name: 'PacketDetails',
    component: PacketDetails,
    meta: {
      requiresAuth: true,
      title: 'GT | Packet - Individual',
      admin : true,
      fsr : false,
      schedular : false
    },
  },

  // enrollments route
  {
    path: '/my-enrollments',
    name: 'MyEnrollments',
    component: MyEnrollments,
    meta: {
      requiresAuth: true,
      title: 'GT | My Enrollments',
      admin : true,
      fsr : true,
      schedular : true
    },
  },
  {
    path: '/enrollments',
    name: 'Enrollments',
    component: Enrollments,
    meta: {
      requiresAuth: true,
      title: 'GT | Enrollments',
      admin : true,
      fsr : true,
      schedular : true
    },
  },
  {
    path: '/enrollments/:id',
    name: 'EnrollmentsDetails',
    component: EnrollmentsDetails,
    meta: {
      requiresAuth: true,
      title: 'GT | Enrollment Details',
      admin : true,
      fsr : true,
      schedular : true
    },
  },
  {
    path: '/enrollments/:id/:documentId/edit/',
    name: 'UploadDocuments',
    component: UploadDocuments,
    meta: {
      requiresAuth: true,
      title: 'GT | Upload Documents',
      admin : true,
      fsr : false,
      schedular : false
    },
  },
  // documents route
  {
    path: '/documents/add',
    name: 'AddDocument',
    component: AddDocument,
    meta: {
      requiresAuth: true,
      title: 'GT | Add Document',
      admin : true,
      fsr : false,
      schedular : false
    },
  },
  {
    path: '/documents/:id',
    name: 'EditDocument',
    component: AddDocument,
    meta: {
      requiresAuth: true,
      title: 'GT | Update Document',
      admin : true,
      fsr : false,
      schedular : false
    },
  },
  {
    path: '/documents',
    name: 'Documents',
    component: Documents,
    meta: {
      requiresAuth: true,
      title: 'GT | Documents',
      admin : true,
      fsr : false,
      schedular : false
    },
  },

  // schedule routes
  {
    path: '/schedule',
    name: 'Schedule',
    component: Schedule,
    meta: {
      requiresAuth: true,
      title: 'GT | Schedule',
      admin : true,
      fsr : true,
      schedular : true
    },
  },
  {
    path: '/schedule/participants',
    name: 'ScheduleParticipantList',
    component: ScheduleParticipantList,
    meta: {
      requiresAuth: true,
      title: 'GT | Schedule',
      admin : true,
      fsr : true,
      schedular : true
    },
  },
  {
    path: '/reschedule-appointment',
    name: 'RescheduleAppointment',
    component: ScheduleParticipantList,
    meta: {
      requiresAuth: true,
      title: 'GT | Schedule',
      admin : true,
      fsr : true,
      schedular : true
    },
  },

  // enrollment presentation routes
  {
    path: '/enrollment-presentation',
    meta: {
      requiresAuth: true,
      requiresJwt: true,
      admin : true,
      fsr : true,
      schedular : false
    },
    children: [
      {
        path: ':enrollmentId/FirstDetails/',
        name: 'FirstEnrollmentSlide',
        component: FirstDetailSlide,
        meta: { title: 'GT | Enrollment - First Details' }
      },
      {
        path: ':enrollmentId/Participants/',
        name: 'ParticipantLandingSlide',
        component: ParticipantLanding,
        meta: { title: 'GT | Enrollment - Participants' }
      },
      {
        path: ':enrollmentId/:enrolleeId/:enrolleeType/',
        async beforeEnter(to, from) {
          if (to.params.enrolleeId && from.fullPath.includes('enroll') && from.name !== 'EnrolleeAuthPage') {
            const user = await userFunctions.getUserInfo();
            userFunctions.addNoteForUser('Enrollment started', user.id, to.params.enrolleeId)
          }
        },
        children: [
          {
            path: 'ParticipantInfo1/',
            name: 'ParticipantInformation1',
            component: ParticipantInfo1,
            meta: { title: 'GT | Enrollment - Participant Info 1' }
          },
          {
            path: 'ParticipantInfo2/',
            name: 'ParticipantInformation2',
            component: ParticipantInfo2,
            meta: { title: 'GT | Enrollment - Participant Info 2' }
          },
          {
            path: 'ParticipantInfo3/',
            name: 'ParticipantInformation3',
            component: ParticipantInfo3,
            meta: { title: 'GT | Enrollment - Participant Info 3' }
          },
          {
            path: 'EOR-Info/',
            name: 'EORInformation',
            component: EORInfo,
            meta: { title: 'GT | Enrollment - EOR Info' }
          },
          {
            path: 'Representative-Information/',
            name: 'RepresentativeInformation',
            component: RepresentativeInfo,
            meta: { title: 'GT | Enrollment - Representative Info' }
          },
          // { path: 'CaseManagerAndAuthorizationInfo1/', name: 'CaseManagerAuthInformation1', component: CaseManagerAuthInfo1, meta: { title: 'GT | Enrollment - Case Manager & Authorization info' } },
          // { path: 'CaseManagerAndAuthorizationInfo2/', name: 'CaseManagerAuthInformation2', component: CaseManagerAuthInfo2, meta: { title: 'GT | Enrollment - Case Manager & Authorization info' } },
          {
            path: 'Welcome-1/',
            name: 'ParticipantWelcome1Slide',
            component: ParticipantWelcome1,
            meta: { title: 'GT | Enrollment - Welcome' }
          },
          {
            path: 'Welcome-2/',
            name: 'ParticipantWelcomeSlide',
            component: ParticipantWelcome2,
            meta: { title: 'GT | Enrollment - Welcome' }
          },
          {
            path: 'Welcome-3/',
            name: 'ParticipantWelcome3Slide',
            component: ParticipantWelcome3,
            meta: { title: 'GT | Enrollment - Welcome' }
          },
          {
            path: 'Welcome-4/',
            name: 'ParticipantWelcome4Slide',
            component: ParticipantWelcome4,
            meta: { title: 'GT | Enrollment - Welcome' }
          },
          {
            path: 'ESign1/',
            name: 'E-Sign1',
            component: ESign1,
            meta: { title: 'GT | Enrollment - ESign1' }
          },
          { path: 'ESign4/',
            name: 'E-Sign4',
            component: ESign4,
            meta: { title: 'GT | Enrollment - ESign4' }
          },
          {
            path: 'ESign5/',
            name: 'E-Sign5',
            component: ESign5,
            meta: { title: 'GT | Enrollment - ESign5' }
          },
          {
            path: 'ESign6/',
            name: 'E-Sign6',
            component: ESign6,
            meta: { title: 'GT | Enrollment - ESign6' }
          },
          {
            path: 'ESign7/',
            name: 'E-Sign7',
            component: ESign7,
            meta: { title: 'GT | Enrollment - ESign7' }
          },
          {
            path: 'ESign8/',
            name: 'E-Sign8',
            component: ESign8,
            meta: { title: 'GT | Enrollment - ESign8' }
          },
          {
            path: 'Summary/',
            name: 'Summary',
            component: Summary,
            meta: { title: 'GT | Enrollment - Summary' }
          },
          { path: 'FinalSteps/',
            name: 'Final-Steps',
            component: FinalSteps,
            meta: { title: 'GT | Enrollment - Final Steps' }
          },
          {
            path: 'FinalSteps/',
            name: 'Final-Steps',
            component: FinalSteps,
            meta: { title: 'GT | Enrollment - Final Steps' }
          },
          {
            path: 'CaregiverInfo1/',
            name: 'CaregiverInformation1',
            component: CaregiverInfo1,
            meta: { title: 'GT | Enrollment - Caregiver Info 1' }
          },
          {
            path: 'CaregiverInfo2/',
            name: 'CaregiverInformation2',
            component: CaregiverInfo2,
            meta: { title: 'GT | Enrollment - Caregiver Info 2' }
          },
          {
            path: 'CaregiverInfo3/',
            name: 'CaregiverInformation3',
            component: CaregiverInfo3,
            meta: { title: 'GT | Enrollment - Caregiver Info 3' }
          },
          {
            path: 'CaregiverInfo4/',
            name: 'CaregiverInformation4',
            component: CaregiverInfo4,
            meta: { title: 'GT | Enrollment - Caregiver Info 4' }
          },
          {
            path: 'CaregiverInfo5/',
            name: 'CaregiverInformation5',
            component: CaregiverInfo5,
            meta: { title: 'GT | Enrollment - Caregiver Info 5' }
          },
          {
            path: 'CaregiverInfo6/',
            name: 'CaregiverInformation6',
            component: CaregiverInfo6,
            meta: { title: 'GT | Enrollment - Caregiver Info 6' }
          },
          {
            path: 'CaregiverCitizenshipStatus/',
            name: 'CaregiverI9EligibilityToWork',
            component: CaregiverI9EligibilityToWork,
            meta: { title: 'GT | Enrollment - I9 Eligibility To Work' }
          },
          {
            path: 'CaregiverI9Identification/',
            name: 'CaregiverI9Identification',
            component: CaregiverI9Identification,
            meta: { title: 'GT | Enrollment - I9 Identification' }
          },
          {
            path: 'CaregiverPreferredPayment/',
            name: 'CaregiverPreferredPayment',
            component: CaregiverPreferredPayment,
            meta: { title: 'GT | Enrollment - Preferred Payment' }
          },
          {
            path: 'CaregiverW4Information/',
            name: 'CaregiverW4Information',
            component: CaregiverW4Info,
            meta: { title: 'GT | Enrollment - W4 Information' }
          },
          {
            path: 'IDCapture/',
            name: 'IDCapture',
            component: IDCapture,
            meta: { title: 'GT | Enrollment - ID Capture' }
          },
          {
            path: 'ImageCapture/',
            name: 'ImageCapture',
            component: ImageCapture,
            meta: { title: 'GT | Enrollment - Image Capture' }
          },
          {
            path: ':documentId/FederalAcknowledgment/',
            name: 'FederalAcknowledgment',
            component: FederalAcknowledgment,
            meta: { title: 'GT | Enrollment - Federal Form Acknowledgment' }
          },
          {
            path: ':documentId/FederalFormFields/',
            name: 'FederalFormFields',
            component: FederalCustomFields,
            meta: { title: 'GT | Enrollment - Federal Form Custom Fields' }
          },
          {
            path: ':documentId/StateAcknowledgment/',
            name: 'StateAcknowledgment',
            component: StateAcknowledgment,
            meta: { title: 'GT | Enrollment - State Form Acknowledgment' }
          },
          {
            path: ':documentId/stateFormFields/',
            name: 'stateFormFields',
            component: StateCustomFields,
            meta: { title: 'GT | Enrollment - State Form Custom Fields' }
          },
          {
            path: ':documentId/ProgramAcknowledgment/',
            name: 'ProgramAcknowledgment',
            component: ProgramAcknowledgment,
            meta: { title: 'GT | Enrollment - Program Form Acknowledgment' }
          },
          {
            path: ':documentId/programFormFields/',
            name: 'programFormFields',
            component: ProgramCustomFields,
            meta: { title: 'GT | Enrollment - Program Form Custom Fields' }
          },
          {
            path: ':documentId/MiscAcknowledgment/',
            name: 'MiscAcknowledgment',
            component: MiscAcknowledgment,
            meta: { title: 'GT | Enrollment - Miscellaneous Form Acknowledgment' }
          },
          {
            path: ':documentId/miscFormFields/',
            name: 'miscFormFields',
            component: MiscCustomFields,
            meta: { title: 'GT | Enrollment - Miscellaneous Form Custom Fields' }
          },
          {
            path: 'VendorInfo1/',
            name: 'VendorInformation1',
            component: VendorInfo1,
            meta: { title: 'GT | Enrollment - Vendor Info 1' }
          },
          {
            path: 'VendorInfo2/',
            name: 'VendorInformation2',
            component: VendorInfo2,
            meta: { title: 'GT | Enrollment - Vendor Info 2' }
          },
        ]
      }
    ]
  },
  // catch all other routes, render 404
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: 'GT | Not Found',
    },
  },  
  {
    path: '/error',
    name: 'Error',
    component: Error,
    meta: {
      title: 'GT | Error',
    },
  },
];

const router = createRouter({
  history: createWebHistory(BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {

  const authUser = store.getters.getIsLoggedIn;
  const jwtAuthUser = store.getters.getLoggedInThroughJwtToken;
  const role = store.getters.getRoleId;

  if(to.meta.requiresAuth && authUser) {
    if (to.path === '/' || to.path === '/signin') {
      return next('/my-enrollments');  // logged in and going to home page, send them to enrollments
    }
    else {
      if (role == USER_ROLES.ADMIN || role == USER_ROLES.SUPERADMIN) return next();
      else if(role == USER_ROLES.FSR){
        if (to.meta.fsr) return next();
        else return next('/my-enrollments');
      }
      else if(role == USER_ROLES.SCHEDULAR){
        if (to.meta.schedular) return next();
        else return next('/my-enrollments');
      }
    }
    return next(); // catch rest and let them go
  } else if(to.meta.requiresJwt && jwtAuthUser) {
    return next();
  } else if(to.meta.requiresJwt && !jwtAuthUser) {
    localStorage.setItem('pathToLoadAfterLogin', to.path)
    return next('/enrollee/auth');
  } else if(to.meta.requiresAuth && !authUser) {
    localStorage.setItem('pathToLoadAfterLogin', to.path)
    return next('/signin'); // not logged in and trying to accessing something, send them to signin
  }
  else next();
})

// add faked route when in local/development mode
if (ENV === 'local' || ENV === 'development') router.addRoute('',
    {
    path: '/faked',
    name: 'fakedLogin',
    component: () => import("Views/Login/FakedSignin.vue"),
    meta: {
      title: 'GT | Faked Login',
      requiresAuth : false
    }
  },
)

export default router;
