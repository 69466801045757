<template>
  <div class="col-span-6 lg:col-span-12 flex">
    <input class="m-1 cursor-pointer" :checked="participantIsEOR" type="checkbox" @change="employerIsParticipant($event)">
    <p class="ml-4 font-normal text-base text-gtDarkBlue">The employer of record is the participant</p>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="first_name" class="block text-gray-700">
      First Name 
        
    </label>
    <div class="flex items-center mt-1">
      <input
        v-model="employerInfo.firstName"
        @input="emitChange('firstName')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="Enter first name here"
        id="first_name"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="middle_initial" class="block text-gray-700"
      >Middle Initial
      
    </label>
    <div class="flex items-center mt-1">
      <input
        v-model="employerInfo.middleInitial"
        @input="emitChange('middleInitial')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        maxlength="1"
        placeholder="Enter middle initial"
        id="middle_initial"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="last_name" class="block text-gray-700">Last Name </label>
    <div class="flex items-center mt-1">
      <input
        v-model="employerInfo.lastName"
        @input="emitChange('lastName')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="Enter last name here"
        id="last_name"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="primary_phone" class="block text-gray-700">Primary Phone </label>
    <div class="mt-1 relative rounded-md">
      <input
        v-model="employerInfo.phonePrimary"
        @input="formatPrimaryNumber; emitChange('phonePrimary')"
        type="tel"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 pl-2 focus:outline-none"
        id="primary_phone"
      />
    </div>
  </div>
  <div class="col-span-6">
    <label for="email" class="block text-gray-700"
      >Email
      
    </label>
    <div class="mt-1 relative rounded-md">
      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <EnvelopeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <input
        v-model="employerInfo.email"
        @input="emitChange('email')"
        type="email"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 pl-10 focus:outline-none"
        placeholder="email@mail.com"
        id="email"
      />
    </div>
  </div>
  <div class="col-span-6">
    <label for="primary_street_address" class="block text-gray-700">Primary Street Address </label>
    <div class="mt-1 relative rounded-md">
      <input
        type="text"
        ref="searchPrimary"
        v-model="primaryAddress"
        @input="emitChange('addressPrimary')"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none pr-8"
        id="primary_street_address"
      />
      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="county" class="block text-gray-700">County </label>
    <div class="flex items-center mt-1">
      <input
        v-model="employerInfo.addressPrimary.county"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="Enter County"
        id="county"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="date_of_birth" class="block text-gray-700">Date of Birth </label>
    <div class="flex items-center mt-1">
      <input
        v-model="employerInfo.dateOfBirth"
        @input="emitChange('dateOfBirth')"
        type="date"
        :max="getCurrentDate()"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
        placeholder="mm/dd/yyyy"
        id="date_of_birth"
      />
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="social_security_number" class="block text-gray-700">Social Security Number </label>
    <div class="mt-1 relative rounded-md">
      <input
        v-model="employerInfo.socialSecurityNumber"
        @input="formatSSN(); emitChange('socialSecurityNumber')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none pr-8"
        placeholder="000-000-0000"
        id="social_security_number"
      />
      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="social_security_number" class="block text-gray-700">EIN</label>
    <div class="mt-1 relative rounded-md">
      <input
        v-model="employerInfo.ein"
        @input="emitChange('ein')"
        type="text"
        class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none pr-8"
        placeholder="00-0000000"
      />
      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
    </div>
  </div>
  <div class="col-span-6 sm:col-span-3">
    <label for="participant_relation" class="block text-gray-700">Relationship to Participant </label>
    <div class="flex items-center mt-1">
      <select
        v-model.number="employerInfo.relationship"
        @input="emitChange('relationship')"
        id="participant_relation"
        name="participant_relation"
        autocomplete="participant_relation"
        class="chevron-down mb-1 h-8 block min-w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none text-gray-700"
      >
        <option selected disabled value="">Select Relationship</option>
        <option :value="relation.id" v-for="relation in relations" :key="relation.id">
          {{ relation.name }}
        </option>
      </select>
      <span class="w-14 -ml-8">
        <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
import {EnvelopeIcon, QuestionMarkCircleIcon} from '@heroicons/vue/20/solid';
import {getCurrentDate, getFormattedDate, maskedSSN, maskPhoneNumber} from 'Utilities/helper';
import addressUtility from 'Utilities/addressUtility';
import {mapActions, mapGetters} from 'vuex';

export default {
  emits: ['employerFormData', 'employerIsParticipant', 'unsavedChange'],
  components: {
    QuestionMarkCircleIcon,
    EnvelopeIcon,
  },
  props: ['employerData', 'participantIsEOR'],
  computed: {
    ...mapGetters('enrollmentModule', ['getRelationships']),
    primaryAddress() {
      const validAddr = this.employerInfo?.addressPrimary;
      return addressUtility.addressToString(validAddr);
    }
  },
  watch: {
    getRelationships(participantRelationship) {
      if (participantRelationship) {
        this.relations = participantRelationship;
      }
    },
  },
  mounted() {
    this.getEmployersData();
    window.checkAndAttachMapScript(this.initLocationSearch);
    this.employerFormData();
    this.loadRelations();
  },
  data() {
    return {
      relations: [],
      employerInfo: {
        firstName: '',
        lastName: '',
        middleInitial: '',
        phonePrimary: '',
        phoneSecondary: '',
        email: '',
        dateOfBirth: '',
        socialSecurityNumber: '',
        ein: '',
        relationship: '',
        addressPrimary: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          zipcode: '',
          state: '',
          county: '',
        },
      },
      placeData: '',
    };
  },
  methods: {
    maskPhoneNumber,
    getFormattedDate,
    getCurrentDate,
    initLocationSearch() {
      const autocomplete = new window.google.maps.places.Autocomplete(this.$refs.searchPrimary, {
        componentRestrictions: { country: ['us'] },
        fields: ['address_components'],
        types: ['address'],
      });

      autocomplete.addListener('place_changed', () => {
        this.employerInfo.addressPrimary = addressUtility.parseAddressFromGoogleObject(autocomplete.getPlace(), autocomplete.gm_accessors_?.place?.Zr?.formattedPrediction);
      });
    },

    getEmployersData() {
      if (Object.keys(this.employerData.employers).length) {
        this.employerInfo = { ...this.employerData.employers };
        this.employerInfo.socialSecurityNumber = this.employerData?.employers?.ssn;
        if (this.employerData.employers?.address) {
          this.employerInfo.addressPrimary = this.employerData.employers?.address;
          this.employerInfo.addressPrimary.state = this.employerData.employers?.address?.stateAbbrev;
        } else {
          this.employerInfo.addressPrimary = {};
        }
      }
    },
    employerFormData() {
      this.$emit('employerFormData', this.employerInfo);
    },
    employerIsParticipant(event) {
      this.$emit('employerIsParticipant', event.target.checked);
    },
    emitChange(field) {
      this.$emit('unsavedChange', field);
    },
    formatPrimaryNumber() {
      this.employerInfo.phonePrimary = maskPhoneNumber(this.employerInfo.phonePrimary);
    },
    formatSSN() {
      this.employerInfo.socialSecurityNumber = maskedSSN(this.employerInfo.socialSecurityNumber);
    },
    ...mapActions('enrollmentModule', ['loadRelations']),
  },
};
</script>
