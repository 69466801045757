<template>
  <div class="vld-parent">
    <loading class="ml-20 gtBlue800" v-model:active="isLoading" :color="color" :is-full-page="fullPage" />
  </div>
  <div class="flex flex-col min-h-screen bg-white">
    <!-- Top nav-->
    <MobileHeader />
    <!-- Bottom section -->
    <div class="flex flex-1 min-h-0">
      <!-- Narrow sidebar-->
      <Nav />
      <!-- Main area -->
      <main class="flex-1 min-w-0 border-t border-gray-200 lg:flex">
        <section aria-labelledby="primary-heading" class="flex flex-col flex-1 h-full min-w-0 lg:order-last">
          <!-- Page Heading -->
          <PageHeading :title="header" />
          <section v-if="!isLoading" class="px-4 mx-12 2xl:mx-12 sm:mx-5 md:mx-5 xl:mx-10 sm:px-6 lg:px-8">
            <div class="pb-16 -my-2 sm:-mx-6 lg:-mx-8">
              <span
                @click="enrollmentChoiceChanged('1')"
                class="font-bold inline-flex float-left cursor-pointer items-center py-1.5 px-3.5 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent"
                :class="enrollmentChoice == '1' ? 'bg-buttonBlue hover:bg-darkBlue text-trueWhite' : 'hover:bg-darkBlue text-buttonBlue hover:text-trueWhite'"
              >
                Open
              </span>
              <span
                @click="enrollmentChoiceChanged('2')"
                class="font-bold inline-flex float-left cursor-pointer items-center py-1.5 px-3.5 ml-2 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent"
                :class="enrollmentChoice == '2' ? 'bg-buttonBlue hover:bg-darkBlue text-trueWhite' : 'hover:bg-darkBlue text-buttonBlue hover:text-trueWhite'"
              >
                Scheduled
              </span>
              <span
                @click="enrollmentChoiceChanged('self-service')"
                class="font-bold inline-flex float-left cursor-pointer items-center py-1.5 px-3.5 ml-2 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent"
                :class="enrollmentChoice == 'self-service' ? 'bg-buttonBlue hover:bg-darkBlue text-trueWhite' : 'hover:bg-darkBlue text-buttonBlue hover:text-trueWhite'"
              >
                Self-Service
              </span>
              <span
                @click="enrollmentChoiceChanged('4')"
                class="font-bold inline-flex float-left cursor-pointer items-center py-1.5 px-3.5 ml-2 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent"
                :class="enrollmentChoice == '4' ? 'bg-buttonBlue hover:bg-darkBlue text-trueWhite' : 'hover:bg-darkBlue text-buttonBlue hover:text-trueWhite'"
              >
                Scheduled Virtual
              </span>
              <span
                @click="enrollmentChoiceChanged('participant-completed')"
                class="font-bold inline-flex float-left cursor-pointer items-center py-1.5 px-3.5 ml-2 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent"
                :class="enrollmentChoice == 'participant-completed' ? 'bg-buttonBlue hover:bg-darkBlue text-trueWhite' : 'hover:bg-darkBlue text-buttonBlue hover:text-trueWhite'"
              >
                Participant Completed
              </span>
              <span
                @click="enrollmentChoiceChanged('5')"
                class="font-bold inline-flex float-left cursor-pointer items-center py-1.5 px-3.5 ml-2 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent"
                :class="enrollmentChoice == '5' ? 'bg-buttonBlue hover:bg-darkBlue text-trueWhite' : 'hover:bg-darkBlue text-buttonBlue hover:text-trueWhite'"
              >
                Completed
              </span>
              <span
                @click="enrollmentChoiceChanged('nhep')"
                class="font-bold inline-flex float-left cursor-pointer items-center py-1.5 px-3.5 ml-2 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent"
                :class="enrollmentChoice == 'nhep' ? 'bg-buttonBlue hover:bg-darkBlue text-trueWhite' : 'hover:bg-darkBlue text-buttonBlue hover:text-trueWhite'"
              >
                NHEP
              </span>
              <span
                @click="enrollmentChoiceChanged('archive')"
                class="font-bold inline-flex float-left cursor-pointer items-center py-1.5 px-3.5 ml-2 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent"
                :class="enrollmentChoice == 'archive' ? 'bg-buttonBlue hover:bg-darkBlue text-trueWhite' : 'hover:bg-darkBlue text-buttonBlue hover:text-trueWhite'"
              >
                Archive
              </span>
              <span
                @click="enrollmentChoiceChanged('all')"
                class="font-bold inline-flex float-left cursor-pointer items-center py-1.5 px-3.5 ml-2 rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent"
                :class="enrollmentChoice == 'all' ? 'bg-buttonBlue hover:bg-darkBlue text-trueWhite' : 'hover:bg-darkBlue text-buttonBlue hover:text-trueWhite'"
              >
                All
              </span>
              <div class="inline-block w-full mt-4 overflow-auto align-middle rounded-lg surrounding-shadow">
                <div class="border-b border-gray-200 sm:rounded-lg w-max lg:w-auto">
                  <div class="flex">
                    <nav class="flex items-center justify-between px-2 py-3 bg-white sm:px-6" aria-label="Pagination">
                      <!-- Hide New Enrollment Button -->
                      <!-- <div class="hidden sm:block">
                      <span
                        class="inline-flex items-center cursor-pointer py-1.5 px-2.5 bg-gtBlue500 text-white"
                      >
                        <button type="button" class="gt-btn">
                          <span class="sr-only">Remove large option</span>
                          <svg
                            class="w-5 h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                            />
                          </svg>
                        </button>
                        New Enrollment
                      </span>
                    </div> -->
                      <div class="-ml-4 lg:-ml-3">
                        <SearchBar v-model:searchQuery="searchQuery" classes="w-72" />
                      </div>
                      <div class="flex items-center ml-2 lg:ml-4">
                        <Multiselect
                          v-model="params.state"
                          class="mr-4 bg-white border border-gray-200 rounded-md cursor-pointer search_filters filterBg"
                          placeholder="Search by State"
                          @change="stateChanged"
                          :options="multiStateOptions"
                          :searchable="true"
                        />
                      </div>
                      <div class="flex items-center -ml-2 lg:ml-0">
                        <Multiselect
                          @change="onChange"
                          placeholder="Search by Agency"
                          class="mr-4 bg-white border border-gray-200 rounded-md cursor-pointer search_filters"
                          v-model="value"
                          mode="multiple"
                          label="label"
                          trackBy="label"
                          :appendNewTag="false"
                          :clearOnSelect="false"
                          :hideSelected="false"
                          :createTag="false"
                          :searchable="true"
                          :options="agenciesInStates"
                        >
                          <template v-slot:option="{ option }">
                            <img v-if="option.selected" class="w-5 h-5 mr-1 user-image" :src="option.image" />

                            <span :class="option.selected ? ' text-gtGray800' : ' text-gtGray800 ml-4'"> {{ option.label }}</span>
                          </template>

                          <template v-slot:multiplelabel="{ values }">
                            <div v-if="values.length > 1" class="multiselect-multiple-label">Multiple Selected ({{ values.length }})</div>
                            <div v-else class="multiselect-multiple-label">
                              {{ values[0].label }}
                            </div>
                          </template>
                        </Multiselect>
                      </div>
                      <div v-if="showStatusFilter" class="flex items-center -ml-2 lg:ml-0">
                        <Multiselect
                          v-model="params.status"
                          class="bg-white border border-gray-200 rounded-md cursor-pointer search_filters filterBg"
                          placeholder="Filter by Status"
                          @change="statusChanged"
                          :options="multiEnrollmentStatusOptions"
                          :searchable="true"
                        />
                      </div>
                      <div v-if="enrollmentChoice === '1' && onlySpecialist" class="flex items-center -ml-2 lg:ml-0">
                        <Multiselect
                          v-model="params.priority"
                          class="bg-white border ml-4 border-gray-200 rounded-md cursor-pointer search_filters filterBg"
                          placeholder="Filter by Priority"
                          @change="priorityChanged"
                          :options="[
                            { value: 1, label: 'Critical' },
                            { value: 2, label: 'Important' },
                            { value: 3, label: 'Standard' },
                          ]"
                          :searchable="true"
                        />
                      </div>
                    </nav>
                    <div class="inset-y-0 right-0 flex items-center justify-end flex-1 pr-2 bg-white rounded-t-lg sm:static sm:inset-auto sm:pr-0">
                      <div class="flex items-center h-full pl-4 pr-0 border-l-2 border-gray-300 right-1">
                        <label for="view" class="block text-gray-700">View</label>
                        <select
                          v-model="params.limit"
                          @change="changeEnrollmentsLimit($event)"
                          id="view"
                          name="view"
                          class="block w-full px-1 pb-1 mt-1 ml-0 bg-white rounded-md cursor-pointer chevron-down 2xl:ml-1 text-gtGray900 focus:outline-none focus:ring-gtGray500 focus:border-gtGray500"
                        >
                          <option class="" v-for="(option, key) in options" :value="option" :key="key">
                            {{ option }}
                          </option>
                        </select>
                        <svg class="w-10 h-6 mb-1 -ml-2 text-gtGrayDownIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="w-auto table-container-1 lg:w-full">
                    <div class="table-container-2">
                      <div class="fixed flex justify-center my-3 text-gray-500 left-1/2 top-1/3" v-if="showSpinner">
                        <svg class="h-8 mr-3 -ml-1 animate-spin w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      </div>
                      <table class="min-w-full divide-y divide-gray-200 table-auto" :class="showSpinner ? 'opacity-25' : ''">
                        <thead class="bg-gtBlue50">
                          <tr>
                            <th v-if="enrollmentChoice === '1' && onlySpecialist" scope="col" class="pl-6 py-3 tracking-wider text-left text-gray-500">
                              Priority
                            </th>
                            <th scope="col" class="px-6 py-3 tracking-wider text-left text-gray-500">
                              Participant
                            </th>
                            <th scope="col" class="px-6 py-3 tracking-wider text-center text-gray-500">Scheduling</th>
                            <th scope="col" class="px-6 py-3 tracking-wider text-center text-gray-500">Agency</th>
                            <th scope="col" class="px-6 py-3 tracking-wider text-center text-gray-500">Caregiver</th>
                            <th scope="col" class="px-6 py-3 tracking-wider text-center text-gray-500">Date Added</th>
                            <th scope="col" class="px-6 py-3 tracking-wider text-center text-gray-500">State</th>
                            <th scope="col" class="px-6 py-3 tracking-wider text-left text-gray-500">Status</th>
                            <th scope="col" class="px-6 py-3 tracking-wider text-left text-gray-500"></th>
                            <th scope="col" class="relative px-6 py-3">
                              <span class="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                          <tr class="cursor-pointer hover:bg-gtBlue" v-for="enrollment in allEnrollments" :key="enrollment.id">
                            <td v-if="enrollmentChoice === '1' && onlySpecialist" scope="col" class="pl-6 py-3 tracking-wider text-left">
                              <svg v-if="enrollment?.priority === 1"
                                xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 24 24" 
                                fill="currentColor" 
                                stroke="white" 
                                stroke-width="2"
                                stroke-linecap="round" 
                                stroke-linejoin="round"
                                class="text-scarlet w-8 h-8"
                              >
                                <circle stroke="currentColor" cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="12"></line>
                                <line x1="12" y1="16" x2="12.01" y2="16"></line>
                              </svg>
                              <svg v-else-if="enrollment?.priority === 2"
                                xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 24 24" 
                                fill="currentColor" 
                                stroke="white" 
                                stroke-width="2"
                                stroke-linecap="round" 
                                stroke-linejoin="round"
                                class="text-gold w-8 h-8"
                              >
                                <circle stroke="currentColor" cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="12"></line>
                                <line x1="12" y1="16" x2="12.01" y2="16"></line>
                              </svg>
                              <svg v-else-if="enrollment?.priority === 3" 
                                xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 24 24" 
                                fill="none" 
                                stroke="gray" 
                                stroke-width="2"
                                stroke-linecap="round" 
                                stroke-linejoin="round"
                                class="w-8 h-8"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="12"></line>
                                <line x1="12" y1="16" x2="12.01" y2="16"></line>
                              </svg>
                            </td>
                            <td @click="goToEnrollmentDetails(enrollment.id)" class="px-5 py-2 text-gray-900 whitespace-pre-line">
                              <template v-if="enrollment.participants.length">
                                {{ enrollment?.participants[0]?.firstName }}
                                {{ enrollment?.participants[0]?.lastName }}
                              </template>
                            </td>
                            <td v-if="checkScheduledOrVirtual(enrollment)" @click="goToEnrollmentDetails(enrollment.id)" class="px-5 py-2 text-center text-gray-500 whitespace-nowrap">
                              {{ enrollment?.schedule?.name }}
                              <p class="text-gtGray500">
                                {{ scheduledDate(enrollment) }} 
                                {{ scheduledTime(enrollment) }}
                              </p>
                            </td>
                            <td v-else>&nbsp;</td>
                            <td @click="goToEnrollmentDetails(enrollment.id)" class="px-5 py-2 text-center text-gray-500 whitespace-pre-line">
                              {{ enrollment?.agency?.name }}
                            </td>
                            <td @click="goToEnrollmentDetails(enrollment.id)" class="px-5 py-2 text-center text-gray-500 whitespace-pre-line">
                              <template v-if="enrollment.caregivers.length">
                                {{ enrollment?.caregivers[0]?.firstName }}
                                {{ enrollment?.caregivers[0]?.lastName }}
                              </template>
                            </td>
                            <td @click="goToEnrollmentDetails(enrollment.id)" class="px-5 py-2 text-center text-gray-500 whitespace-pre-line">
                              {{ getFormattedDate(enrollment?.createdAt, 'L') }}
                            </td>
                            <td @click="goToEnrollmentDetails(enrollment.id)" class="px-5 py-2 text-center text-gray-500 whitespace-pre-line">
                              {{ enrollment?.region?.state?.name }}
                            </td>
                            <td class="px-5 py-2 text-left text-gray-500 whitespace-pre-line">
                              <div class="flex items-center justify-start">
                                <span
                                  :class="enrollment?.status?.id == 1 ? 'bg-gtRed100 text-gtRed800' : enrollment?.status?.id == 2 || enrollment?.status?.id == 7 || enrollment?.status?.id == 8 ? 'bg-gtGreen100 text-gtGreen800' : enrollment?.status?.id == 3 || enrollment?.status?.id == 5 ? 'bg-gtBlue100 text-gtBlue800' : 'bg-gtYellow100 text-gtYellow800'"
                                  class="inline-flex items-center px-2.5 py-0.5 rounded-xl text-center"
                                >
                                  {{ shortName(enrollment?.status?.name) }}
                                </span>
                              </div>
                            </td>
                            <td class="px-5 py-2 text-gray-500 whitespace-nowrap">
                              <span
                                v-if="showKebabMenu"
                                @click="handleButtonClick(enrollment, getButtonText(enrollment.scheduleId))"
                                class="flex text-center justify-center cursor-pointer items-center py-1.5 px-4 font-bold rounded-3xl ease-out duration-100 box-border border-solid border-2 border-buttonBlue hover:border-transparent hover:bg-darkBlue text-buttonBlue hover:text-trueWhite"
                                :class="!getButtonText(enrollment.scheduleId).length ? 'hidden' : ''"
                              >
                                {{ getButtonText(enrollment.scheduleId) }}
                              </span>
                            </td>
                            <td class="px-5 py-2 text-right whitespace-nowrap">
                              <dropdown v-if="showKebabMenu" :classes="'dropdown-right'">
                                <template v-slot:trigger>
                                  <button class="text-gray-500 hover:text-gray-900">
                                    <svg class="h-7 w-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                                      />
                                    </svg>
                                  </button>
                                </template>
                                <li v-if="checkScheduledOrVirtual(enrollment)">
                                  <a
                                    @click="rescheduleEnrollment(enrollment)"
                                    href="javascript:void(0)"
                                    class="py-0.5 pl-6 pr-8 text-left leading-loose block text-white hover:bg-gray-200 hover:text-gray-900"
                                    >View in Schedule
                                  </a>
                                </li>
                                <li v-if="checkScheduledOrVirtual(enrollment)">
                                  <a
                                    @click="openRescheduleAppointmentModal(enrollment)"
                                    href="javascript:void(0)"
                                    class="py-0.5 pl-6 pr-8 text-left leading-loose block text-white hover:bg-gray-200 hover:text-gray-900"
                                    >Reschedule
                                  </a>
                                </li>
                                <li v-if="checkIfSelfService(enrollment)">
                                  <a
                                    href="javascript:void(0)"
                                    @click="toggleConfirmationModal(enrollment.id)"
                                    class="py-0.5 pl-6 pr-8 text-left leading-loose block text-white hover:bg-gray-200 hover:text-gray-900"
                                    >Remove Self-Service</a
                                  >
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click="navigateToEnrollmentPresentation(enrollment.id)"
                                    class="py-0.5 pl-6 pr-8 text-left leading-loose block text-white hover:bg-gray-200 hover:text-gray-900"
                                    >Launch Enrollment</a
                                  >
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click="goToEnrollmentDetails(enrollment.id)"
                                    class="py-0.5 pl-6 pr-8 text-left leading-loose block text-white hover:bg-gray-200 hover:text-gray-900"
                                    >Configure Packet</a
                                  >
                                </li>
                                <li v-if="enrollment.showArchiveOptionInKebabMenu">
                                  <a
                                    href="javascript:void(0)"
                                    @click="showArchiveDocModal(enrollment.id)"
                                    class="py-0.5 pl-6 pr-8 text-left leading-loose block text-white hover:bg-gray-200 hover:text-gray-900"
                                    >Archive</a
                                  >
                                </li>
                              </dropdown>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-if="enrollments.length == 0" class="bg-white divide-y divide-gray-200">
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="px-5 py-4 text-gray-400 whitespace-nowrap">No Record Found</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Pagination -->
              <Pagination
                :currentPage="paginationMeta.current_page"
                :lastPage="paginationMeta.last_page"
                @show-previous="showPreviousPage()"
                @show-next="showNextPage()"
                @show-paginated="showPaginated"
              ></Pagination>
            </div>
          </section>

          <div v-if="showDocArchiveModal" class="doc-modal">
            <ArchiveEnrollmentModal :enrollmentid="enrollmentId" @archiveEnrollment="archiveenrollment" @dismissArchiveModal="dismissArchiveModal" @closeModalFromBackDrop="dismissArchiveModal" />
          </div>

          <!-- confirmation modal -->
          <div v-if="showConfirmationModal">
            <ConfirmationModal
              @confirm="selfServiceEnrollment"
              @dismiss="toggleConfirmationModal"
              modalTitle="Remove Self Service"
              modalText="Are you sure you want to remove Self Service for the enrollment?"
            />
          </div>

          <template v-if="showRescheduleAppointmentModal">
            <div>
              <rescheduleAppointmentModal @cancelAppointment="cancelAppointment" @closeModal="dismissModal" />
            </div>
          </template>


        </section>
      </main>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import stateService from 'Services/backend/state';
import agencyService from 'Services/backend/agency';
import enrollmentService from 'Services/backend/enrollments';
import sortService from 'Services/sortService'
import rescheduleAppointmentModal from 'Views/EnrollmentPresentation/TemplateInstances/rescheduleAppointmentModal.vue';
import { getFormattedDate, getFormattedUnixDate } from 'Utilities/helper';
import Multiselect from '@vueform/multiselect';
import ArchiveEnrollmentModal from 'Views/Enrollments/Modals/ArchiveEnrollmentModal.vue';
import { ENROLLMENTS_SCHEDULE_TYPES, ENROLLMENT_STATUS_TYPES } from '@/config';
import userFunctions from "@/utilities/userFunctions";

export default {
  name: 'EnrollmentListing',
  components: {
    Loading,
    Multiselect,
    ArchiveEnrollmentModal,
    rescheduleAppointmentModal
  },
  props: {
    onlySpecialist: {
      type: Boolean,
      default: false
    }
  },
  async mounted() {
    if (window['_genesysJs']) {
      document.getElementById('genesys-messenger').style.display = 'none';
    }
    this.enrollment_schedule_types = ENROLLMENTS_SCHEDULE_TYPES;
    this.user = await userFunctions.getUserInfo();
    this.getAgencies();
    this.getStates();
    this.getEnrollmentStatus();
    this.getEnrollments();
  },
  computed: {
    agenciesInStates() {
      if (this.stateAbbrev == '' || this.stateAbbrev == null) {
        return this.multiOptions.map((e) => {
          return {
            ...e,
            selected: this.agencyOption.find((x) => x === e.value),
          };
        });
      } else {
        return this.multiOptions
            .filter((agency) => {
              return agency.stateAbbrev == this.stateAbbrev;
            })
            .map((e) => {
              return {
                ...e,
                selected: this.agencyOption.find((x) => x === e.value),
              };
            });
      }
    },
    allEnrollments() {
      return this.enrollments.map((enrollment) => {
        if (enrollment.isArchived == true) {
          return { ...enrollment, showArchiveOptionInKebabMenu: false };
        } else {
          return { ...enrollment, showArchiveOptionInKebabMenu: true };
        }
      });
    },
    showStatusFilter() {
      return this.enrollmentChoice !== 'participant-completed';
    },
    header() {
      return (this.onlySpecialist ? 'My ' : '') + 'Enrollments';
    },
  },
  data() {
    return {
      searchQuery: '',
      enrollmentChoice: '1',
      isOpen: false,
      isLoading: false,
      fullPage: true,
      color: '#749EBA',
      showSpinner: false,
      enrollments: [],
      states: [],
      agencies: [],
      enrollmentStatus: [],
      stateAbbrev: '',
      current_page: 1,
      last_page: 1,
      paginationMeta: [],
      value: [],
      multiOptions: [],
      multiStateOptions: [],
      multiEnrollmentStatusOptions: [],
      agencyOption: [],
      showKebabMenu: true,
      enrollmentId: '',
      showDocArchiveModal: false,
      params: {
        status: '',
        state: '',
        onlySpecialist: this.onlySpecialist,
        agency: '',
        query: '',
        page: 1,
        sortBy: 'created_at',
        order: 'desc',
        limit: '30',
        schedule: '1',
        isArchived: false,
        isViewAll: false,
        withPriority: true,
        priority: undefined,
      },
      options: [10, 20, 30, 40, 50],
      enrollment_schedule_types: [],
      showConfirmationModal : false,
      enrollment_id : '',
      showRescheduleAppointmentModal : false,
      rescheduleEnrollmentId : '',
      user: '',
    };
  },
  watch: {
    searchQuery(val) {
      this.searchKeyword(val);
    },
  },
  methods: {
    getFormattedDate,
    getFormattedUnixDate,
    enrollmentChoiceChanged(choice) {
      this.showSpinner = true;

      this.params.page = 1;

      if (this.params['state'] == null || this.params['status'] == null) {
        this.params['state'] = '';
        this.params['status'] = '';
      }

      if (!this.showStatusFilter) this.params.status = '';

      this.enrollmentChoice = choice;
      this.showKebabMenu = (choice != 'archive');

      this.params = {
        ...this.params,
        onlySpecialist: this.onlySpecialist,
        schedule: '',
        isViewAll: false,
        isArchived: false,
        isSelfService: false,
        isNhep: false,
        withPriority: false,
        priority: undefined,
      };

      switch(choice) {
        case '1':
          this.params = {
            ...this.params,
            withPriority: this.params.onlySpecialist,
          }; break;
        case 'all':
          this.params = {
            ...this.params,
            isViewAll: true,
          }; break;
        case 'archive':
          this.params = {
            ...this.params,
            isArchived: true,
          }; break;
        case 'self-service':
          this.params = {
            ...this.params,
            isSelfService: true,
          }; break;
        case 'nhep':
          this.params = {
            ...this.params,
            isNhep: true,
          };
          break;
        case 'participant-completed': 
          this.params = {
            ...this.params,
            status: ENROLLMENT_STATUS_TYPES.PARTICIPANT_COMPLETED,
          };
          break;
        default:
          this.params = {
            ...this.params,
            schedule: choice,
            order: choice == 2 || choice == 4 ? 'asc' : '',
          };
      }

      this.getEnrollments();
    },
    getEnrollments() {
      if (!this.showSpinner) this.isLoading = true;
      if (!this.onlySpecialist && !this.params.priority) this.params.withPriority = false
      enrollmentService.getEnrollments(this.params).then(
        (res) => {
          this.isLoading = false;
          this.showSpinner = false;
          if (!res) {
            this.$toast(false, "Couldn't get enrollments", "Empty response from server");
          }
          if (res.name == "AxiosError") {
            if (typeof res.response?.data == 'string') {
              if (res.response.data.includes('ECONNREFUSED')) {
                this.$toast(false, "Couldn't get enrollments", "Can't connect to server");
              } else if (res.response.data.includes('ECONNABORTED')) {
                this.$toast(false, "Couldn't get enrollments", "Connection timed out");
              } else {
                this.$toast(false, "Couldn't get enrollments", res.response.data);
              }
            } else {
              this.$toast(false, "Couldn't get enrollments", "See console for detailed error message");
            }
          }
          if (this.enrollmentChoice == 2 || this.enrollmentChoice == 4) {
            this.enrollments = sortService.sortByDateAscending(res.data.data, 'timestamp', ['participants', 'appointments']);
          } else {
            this.enrollments = res.data.data;
          }
          this.paginationMeta = res.data.meta;
          if (this.showAlertForArchiveEnrolment) {
            this.$toast(true, 'Enrollment archived successfully!', 'This enrollment will no longer display here.')
              .then(() => {
                this.showAlertForArchiveEnrolment = false;
              });
          }
        },
        (error) => {
          this.isLoading = false;
          this.showSpinner = false;
          console.error(error);
        },
      );
    },
    getStates() {
      stateService.getStates().then(
        (response) => {
          this.states = sortService.sortAlphabeticalByKey(response.data.states, 'name');
          this.states.forEach((state) => {
            const dd = {
              value: state.abbrev,
              label: state.name,
            };
            this.multiStateOptions.push(dd);
          });
        },
        (error) => {
          console.error(error);
        },
      );
    },
    getAgencies() {
      agencyService.getAgencies().then(
        (response) => {
          this.agencies = sortService.sortAlphabeticalByKey(response.data, 'name');
          this.agencies.forEach((agency) => {
            const dd = {
              value: agency.id,
              label: agency.name,
              stateAbbrev: agency.stateAbbrev,
              image: require('@/assets/tick.svg'),
            };
            this.multiOptions.push(dd);
          });
        },
        (error) => {
          console.error(error);
        },
      );
    },
    getEnrollmentStatus() {
      enrollmentService.getEnrollmentStatus().then(
        (response) => {
          this.enrollmentStatus = sortService.sortAlphabeticalByKey(response.data, 'name');
          const index = this.enrollmentStatus.findIndex((key) => key.name === 'Pending');
          this.enrollmentStatus.splice(index, 1);

          this.enrollmentStatus.forEach((status) => {
            const dd = {
              value: status.id,
              label: status.name,
            };
            this.multiEnrollmentStatusOptions.push(dd);
          });
        },
        (error) => {
          console.error(error);
        },
      );
    },
    stateChanged(e) {
      if (this.params['status'] == null) {
        this.params['status'] = '';
      }

      if (e == null) {
        this.stateAbbrev = '';
        this.showSpinner = true;
        this.params = {
          ...this.params,
          agency: '',
          state: '',
        };
        this.value = [];
        this.getEnrollments();
      } else {
        this.stateAbbrev = e;
        this.showSpinner = true;
        this.params = {
          ...this.params,
          agency: '',
          state: this.stateAbbrev,
        };
        this.value = [];

        this.getEnrollments();
      }
    },
    agencyChanged(e) {
      this.showSpinner = true;
      this.params = {
        ...this.params,
        agency: e.target.value,
      };
      this.getEnrollments();
    },
    statusChanged(e) {
      if (this.params['state'] == null || this.params['status'] == null) {
        this.params['state'] = '';
        this.params['status'] = '';
      }

      if (e == null) {
        this.showSpinner = true;
        this.params = {
          ...this.params,
          status: '',
        };

        this.getEnrollments();
      } else {
        this.showSpinner = true;
        this.params = {
          ...this.params,
          status: e,
        };
        this.getEnrollments();
      }
    },
    priorityChanged(value) {
      if (value == null) {
        this.showSpinner = true;
        this.params = {
          ...this.params,
          priority: undefined,
        };

        this.getEnrollments();
      } else {
        this.showSpinner = true;
        this.params = {
          ...this.params,
          priority: value,
        };
        this.getEnrollments();
      }
    },
    openRescheduleAppointmentModal(enrollment) {
      this.showRescheduleAppointmentModal = true;
      this.rescheduleEnrollmentId = enrollment.id;
    },
    cancelAppointment(reason) {
      this.showRescheduleAppointmentModal = false;
      const index = this.enrollments.findIndex(e => e.id === this.rescheduleEnrollmentId);
      const enrollment = this.enrollments.at(index);
      // save a note for the cancellation
      const cancelNote = {
        text: `Meeting cancelled - was scheduled at ${this.scheduledDate(enrollment)} ${this.scheduledTime(enrollment)} - ${reason}`,
        userId: this.user.id,
        enrolleeId: enrollment.participants[0].id
      }
      enrollmentService.saveNotes(cancelNote);
      // set enrollment to open
      enrollmentService.updateStatusAndScheduleType({enrollmentId: this.rescheduleEnrollmentId, scheduleId: ENROLLMENTS_SCHEDULE_TYPES.OPEN});
      // find the enrollment in our array and remove it.
      this.enrollments.splice(index, 1);
    },
    dismissModal() {
      this.showRescheduleAppointmentModal = false;
      this.rescheduleEnrollmentId = null;
    },
    searchKeyword(query) {
      if (this.params['state'] == null || this.params['status'] == null) {
        this.params['state'] = '';
        this.params['status'] = '';
      }

      this.showSpinner = true;
      (this.params['query'] = query);
      (this.params['page'] = 1);
      this.getEnrollments();
    },
    showPaginated(page) {
      this.paginationMeta.current_page = page;
      this.params['page'] = page;
      if (this.paginationMeta.current_page <= this.paginationMeta.last_page) {
        this.showSpinner = true;
        this.getEnrollments();
      }
    },
    showPreviousPage() {
      this.params['page'] = this.paginationMeta.current_page - 1;
      this.showSpinner = true;
      this.getEnrollments();
    },
    showNextPage() {
      this.params['page'] = this.paginationMeta.current_page + 1;
      this.showSpinner = true;
      this.getEnrollments();
    },
    // Currently not in use, UI control removed 11/02/2021
    setSorting(fieldName) {
      this.params = {
        ...this.params,
        sortBy: fieldName,
        order: this.params.order == 'asc' ? 'desc' : 'asc',
      };
      this.showSpinner = true;
      this.getEnrollments();
    },

    onChange(event) {
      if (this.params['state'] == null || this.params['status'] == null) {
        this.params['state'] = '';
        this.params['status'] = '';
      }

      this.agencyOption = event;
      this.showSpinner = true;
      this.params = {
        ...this.params,
        agency: event.toString(),
      };
      this.getEnrollments();
    },
    changeEnrollmentsLimit(event) {
      this.params['limit'] = event.target.value;
      this.params['page'] = 1;
      this.showSpinner = true;
      this.getEnrollments();
    },
    goToEnrollmentDetails(id) {
      this.$router.push({ name: 'EnrollmentsDetails', params: { id: id } });
    },
    showArchiveDocModal(id) {
      this.enrollmentId = id;
      this.showDocArchiveModal = true;
    },
    archiveenrollment(event) {
      this.showSpinner = true;
      enrollmentService.archiveEnrollment(event).then(
        () => {
          this.showAlertForArchiveEnrolment = true;
          this.getEnrollments();
          this.showSpinner = false;
        },
        (error) => {
          this.showSpinner = false;
          console.error(error);
        },
      );
    },
    dismissArchiveModal() {
      this.showDocArchiveModal = false;
    },
    navigateToEnrollmentPresentation(id) {
      this.$router.push({ name: 'FirstEnrollmentSlide', params: { enrollmentId: id } });
    },
    rescheduleEnrollment(enrollment) {
      const params = {
        agency: enrollment.agencyId,
        state: enrollment.region.stateAbbrev,
        enroleeId: enrollment.participants[0].id,
      };
      this.$store.commit('setEnrollmentId', enrollment.participants[0].id);
      this.$store.commit('setRescheduleParams', params);
      this.$router.push({ name: 'RescheduleAppointment' });
    },
    shortName(name) {
      return name === 'Configuration Needed' ? 'Config. Needed' : name;
    },
    scheduledDate(enrollment){
      return enrollment.participants[0]?.appointments.length ? getFormattedUnixDate(enrollment.participants[0]?.appointments[enrollment.participants[0].appointments.length - 1].timestamp, 'l') : ''
    },
    scheduledTime(enrollment){
      return enrollment.participants[0]?.appointments.length ? getFormattedUnixDate(enrollment.participants[0]?.appointments[enrollment.participants[0].appointments.length - 1].timestamp, 'LT') : ''
    },  
    getButtonText(scheduleId) {
      let buttonText = '';
      switch (scheduleId) {
        case this.enrollment_schedule_types.OPEN:
          buttonText = 'Schedule';
          break;
        case this.enrollment_schedule_types.SCHEDULED:
          buttonText = 'Launch Enrollment';
          break;
        case this.enrollment_schedule_types.SCHEDULED_VIRTUAL:
          buttonText = 'Launch Enrollment';
          break;
        case this.enrollment_schedule_types.SELF_SERVICE:
          buttonText = 'Send Enrollment';
          break;
        default:
          break;
      }
      return buttonText;
    },
    handleButtonClick(enrollment, button) {
      switch (button) {
        case 'Schedule':
          this.scheduleOpenEnrollment(enrollment);
          break;
        case 'Launch Enrollment':
          if (enrollment.scheduleId == this.enrollment_schedule_types.SCHEDULED || enrollment.scheduleId == this.enrollment_schedule_types.SCHEDULED_VIRTUAL) {
            this.navigateToEnrollmentPresentation(enrollment.id);
          }
          break;
        case 'Send Enrollment':
          if (enrollment.scheduleId == this.enrollment_schedule_types.SELF_SERVICE) {
            this.sendEmails(enrollment.id);
          }
          break;
        default:
          break;
      }
    },
    scheduleOpenEnrollment(enrollment) {
      const params = {
        agency: enrollment.agencyId,
        state: enrollment.region.stateAbbrev,
      };
      this.$store.commit('setEnrollmentId', enrollment.participants[0].id);
      this.$store.commit('setIfFromEnrollments', true);
      this.$store.commit('setScheduleData', params);
      this.$router.push({ name: 'ScheduleParticipantList' });
    },
    sendEmails(id) {
       const params = {
        enrollmentId: id,
      };
      this.showSpinner = true;
      enrollmentService.sendEmails(params).then(
        (response) => {
          this.showSpinner = false;
          // this is for the 500 error
          if (response == 'Error: Request failed with status code 500') {
            this.$toast(false, 'Email not sent.', 'Please try again after sometime.');
          } else {
            this.$toast(true, response.data.message);
          }
        },
        (error) => {
          this.showSpinner = false;
          this.$toast(false, 'Email not send.', 'Please try again after sometime.');
          console.error(error);
        },
      );
    },
    checkScheduledOrVirtual(enrollment) {
      return enrollment.scheduleId == this.enrollment_schedule_types.SCHEDULED || enrollment.scheduleId == this.enrollment_schedule_types.SCHEDULED_VIRTUAL;
    },
    checkIfSelfService(enrollment) {
      return enrollment.scheduleId == this.enrollment_schedule_types.SELF_SERVICE;
    },
    toggleConfirmationModal(enrollment_id = '') {
      if(enrollment_id) {
        this.enrollment_id = enrollment_id;
      }
      this.showConfirmationModal = !this.showConfirmationModal;
    },
    selfServiceEnrollment() {
      this.showConfirmationModal = false;
      const params = {
        enrollmentId: this.enrollment_id,
        scheduleId: this.enrollment_schedule_types.OPEN,
      };
      this.showSpinner = true;
      enrollmentService.updateStatusAndScheduleType(params)
      .then(() => {
        this.$toast(true, 'Self service removed successfully!');
        this.showSpinner = false;
        setTimeout(() => {
          this.enrollmentChoiceChanged('1');
        }, 500);
      },
      (error) => {
        this.showSpinnerWhenChangingScheduleType = false;
        console.error(error);
      });
    }
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style this.$scopedSlots.default({ })>
multiselect-tag i::before {
  color: #000;
}

.multiselect-tag.is-user {
  border-radius: 22px;
  background: white;
  color: #000;
  margin: 1px 1px 4px;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
}

.multiselect-tag.is-user img {
  width: 18px;
  border-radius: 50%;
  height: 18px;
  margin-right: 8px;
  border: 2px solid #ffffffbf;
}

.multiselect-tag.is-user i:before {
  color: #000;
  border-radius: 50%;
}

.filterBg .multiselect-options .is-selected {
  background: #2f4c5d;
}

.multiselect-option {
  white-space: nowrap;
  font-size: 14px;
  color: #111827;
  line-height: 20px;
  font-style: normal;
  width: 100%;
  margin-top: 12px;
}

.multiselect-input {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #111827;
  font-style: normal;
}

.table-container-1 {
  display: table;
  table-layout: fixed;
}

.search_filters {
  /* width: 15vw; */
  font-size: 14px;
}

.search_width {
  width: 18vw;
  font-size: 14px;
}

@media screen and (max-width: 1200px) {
  .multiselect-placeholder {
    padding-left: 4px;
  }
}

@media screen and (max-width: 1000px) {
  .multiselect-search {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
