.
<template>
  <div class="w-full min-h-screen bg-white md:overflow-x-visible" :class="showSpinner ? 'opacity-25' : ''">
    <div class="flex flex-1 h-screen">
      <main class="flex-1 h-full min-w-0 px-4 pt-4 overflow-x-hidden overflow-y-auto sm:pt-12 2xl:pt-2 lg:px-16 2xl:px-60 lg:flex"><div class="hidden lg:block w-96"></div>
        <section aria-labelledby="primary-heading" class="flex flex-col flex-1 h-full min-w-0 overflow-x-visible pt-14 lg:pt-0 lg:order-last">
          <TransitionScreen>
            <template v-slot:header>
              <h1 class="px-4 mt-6 text-3xl text-darkBlue lg:mt-16">
                Let’s Get Started
              </h1>
            </template>

            <template v-slot:description>
              <h3 class="px-4 mt-4 mb-10 text-base font-normal text-darkBlue md:text-xl">
                Now that we’ve given you a brief overview of the roles, let's fill out your enrollment paperwork.
              </h3>
            </template>

            <template v-slot:content>
              <Welcome4Content class="px-4" />
            </template>

            <template v-slot:callout> </template>

            <template v-slot:footer>
              <ContinueBackButtons @continue="proceedToNext" @back="returnToPrevious"></ContinueBackButtons>
            </template>
          </TransitionScreen>
        </section>
      </main>
      <AppSideNavMenu :participantDetails="participant" :slide="slideName" :id="enrollmentId"/>
    </div>
  </div>
  <GenesysChat></GenesysChat>

  <!-- Spinner -->
  <div class="fixed flex justify-center my-3 text-gray-500 left-1/2 top-1/2" v-if="showSpinner">
    <svg class="h-8 mr-3 -ml-1 animate-spin w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
</template>

<script>
import TransitionScreen from 'Views/EnrollmentPresentation/StaticScreens/transition.vue';
import Welcome4Content from 'Views/EnrollmentPresentation/TemplateInstances/welcome4_content.vue';
import {mapActions, mapGetters} from 'vuex';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';
import GenesysChat from "@/views/Shared/GenesysChat.vue";

export default {
  name: 'Welcome4Slide',

  components: {
    GenesysChat,
    TransitionScreen,
    Welcome4Content,
    ContinueBackButtons,
  },
  data() {
    return {
      participant: {
        firstName: '',
        lastName: '',
      },
      slideName: 'Welcome',
      enrollmentId: '',
      enrolleeId: '',
      showSpinner: true,
      enrolleeType: '',
    };
  },

  methods: {
    proceedToNext() {
      if (this.showSpinner) return;
      if (this.enrolleeType == 1) {
        this.$router.push({ name: 'ParticipantInformation1', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
      } else if (this.enrolleeType == 2) {
        this.$router.push({ name: 'CaregiverInformation1', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
      }
    },
    returnToPrevious() {
      if (this.showSpinner) return;
      // if (this.enrolleeType == 2) {
      //   this.$router.push({ name: 'ParticipantWelcomeSlide', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
      // } else if (this.enrolleeType == 1) {
        this.$router.push({ name: 'ParticipantWelcome3Slide', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
      // }
    },
    ...mapActions('enrollmentModule', ['loadEnrollees']),
  },
  computed: {
    ...mapGetters('enrollmentModule', ['getEnrollees']),
  },
  watch: {
    getEnrollees(newEnrollees) {
      if (newEnrollees) {
        this.showSpinner = false;
        if (this.enrolleeType == 1) {
          this.participant = {
            firstName: newEnrollees?.participant?.firstName,
            lastName: newEnrollees?.participant?.lastName,
          };
        } else if (this.enrolleeType == 2) {
          this.participant = {
            firstName: newEnrollees?.caregiver?.firstName,
            lastName: newEnrollees?.caregiver?.lastName,
          };
        }
      }
    },
  },
  async created() {
    this.enrollmentId = this.$route.params.enrollmentId;
    this.enrolleeId = this.$route.params.enrolleeId;
    this.enrolleeType = this.$route.params.enrolleeType;
    await this.loadEnrollees(this.$route.params.enrolleeId);
  },
};
</script>

<style></style>
