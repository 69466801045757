import { createStore } from 'vuex';
import enrollmentService from '../services/backend/enrollments';
import createPersistedState from 'vuex-persistedstate';

export const store = createStore({
  modules: {
    enrollmentModule: {
      namespaced: true,
      state: {
        enrollmentDetails: null,
        documentType: null,
        fullPacket: [],
        currentEnrollee: null,
        enrollmentId: null,
        relationships: [],
      },

      getters: {
        getEnrollees(state) {
          return state.enrollmentDetails;
        },
        getFullPacket(state) {
          return state.fullPacket;
        },
        currentEnrollee(state) {
          return state.currentEnrollee;
        },
        enrolleeType(state) {
          return state.currentEnrollee.type;
        },
        enrollmentId(state) {
          return state.enrollmentId;
        },
        getRelationships(state) {
          return state.relationships;
        },
      },

      mutations: {
        SET_ENROLLMENT(state, enrollmentDetails) {
          state.enrollmentDetails = enrollmentDetails;
        },
        SET_FULL_PACKET(state, fullPacket) {
          state.fullPacket = fullPacket;
        },
        SET_CURRENT_ENROLLEE(state, enrollee) {
          state.currentEnrollee = enrollee;
        },
        SET_ENROLLMENT_ID(state, enrollmentId) {
          state.enrollmentId = enrollmentId;
        },
        SET_EMPLOYER_RELATIONSHIPS(state, relations) {
          state.relationships = relations;
        },
      },

      actions: {
        async loadEnrollees({ commit }, params) {
          await enrollmentService
            .getEnrollees(params)
            .then((enrollmentDetails: any) => {
              const enrollment = enrollmentDetails?.data[0];
              const currentEnrollee = enrollmentDetails?.data[0].participant || enrollmentDetails?.data[0].caregiver || enrollmentDetails?.data[0].vendor;

              commit('SET_ENROLLMENT', enrollment);
              commit('SET_CURRENT_ENROLLEE', currentEnrollee);
              commit('SET_ENROLLMENT_ID', currentEnrollee.id); // todo: should populate this value, from a different method

              return enrollmentDetails;
            })
            .then((enrollmentDetails) => {
              const documents = enrollmentDetails.data[0].documents;
              if (documents) {
                const fullPacket = documents.packet;
                Object.keys(documents.enrollee).forEach((key) => {
                  fullPacket[key].splice(fullPacket[key].length, 0, ...documents.enrollee[key]);
                });
                commit('SET_FULL_PACKET', fullPacket);
              }
            })
            .catch((error) => {
              console.error('Something went wrong: ', error.message);
            });
        },
        async loadRelations({ commit }) {
          await enrollmentService
            .getEmployerRelationships()
            .then((res: any) => {
              const relations = res.data;
              commit('SET_EMPLOYER_RELATIONSHIPS', relations);
            })
            .catch((error) => {
              console.error('Something went wrong: ', error.message);
            });
        },
      },
    },
    scheduleModule: {
      state: {
        scheduleAgencyAndStateData: {},
        rescheduleParams: {},
        fromEnrollments: false,
        enrollmentId: '',
      },
      mutations: {
        setScheduleData(state, payload) {
          state.scheduleAgencyAndStateData = payload;
        },
        setRescheduleParams(state, payload) {
          state.rescheduleParams = payload;
        },
        setIfFromEnrollments(state, payload) {
          state.fromEnrollments = payload;
        },
        setEnrollmentId(state, payload) {
          state.enrollmentId = payload;
        },
      },
      getters: {
        getScheduleData: (state) => state.scheduleAgencyAndStateData,
        getRescheduleParams: (state) => state.rescheduleParams,
        getIfFromEnrollments: (state) => state.fromEnrollments,
        getEnrollmentId: (state) => state.enrollmentId,
      },
    },
    userModule: {
      state: {
        isLoggedIn: false,
        loggedInWithJwt: false,
        jwtToken: '',
        enrolleeRoleId: '',
        user: {},
      },
      mutations: {
        setLogin(state, payload) {
          state.isLoggedIn = payload;
        },
        setJwtToken(state, payload) {
          state.jwtToken = payload;
        },
        setLoggedInThroughJwtToken(state, payload) {
          state.loggedInWithJwt = payload;
        },
        setEnrolleeRoleId(state, payload) {
          state.enrolleeRoleId = payload;
        },
        SET_USER(state, user) {
            if (Object.getOwnPropertyNames(user).length) state.user = user
            else state.user = {}
        },
        resetUserState(state) {
            state.user = {}
        },
      },
      getters: {
        getIsLoggedIn: (state) => state.isLoggedIn,
        getJwtToken: (state) => state.jwtToken,
        getLoggedInThroughJwtToken: (state) => state.loggedInWithJwt,
        getEnrolleeRoleId: (state) => state.enrolleeRoleId,
        getUserInfo: (state) => state.user.fullName || '',
        getUserId: (state) => state.user?.id || 0,
        getRoleId: (state) => state.user?.roleId || 0,
        getUser: (state) => state.user,
        isUserPopulated: (state) => Boolean(Object.getOwnPropertyNames(state.user).length),
      },
      actions: {
        forceLogoff: ({ commit }) => {
          commit('setLogin', false);
          commit('setLoggedInThroughJwtToken', false);
          commit('setLogin', false);
          commit('resetUserState');
        },
        setUser: async ({ commit }, user) => {
            commit('SET_USER', user)
        },
      },
    },
    docusignModule: {
      namespaced: true,
      state: {
        lastEnvelopeId: '',
        embeddedSigning: false,
      },
      mutations: {
        SET_LAST_ENVELOPE_ID(state, envelopeId) {
          state.lastEnvelopeId = envelopeId;
        },
        SET_EMBEDDED_SIGNING(state, isEmbed) {
          state.embeddedSigning = isEmbed;
        },
      },
      getters: {
        getlastEnvelopeId: (state) => state.lastEnvelopeId,
        isEmbeddedSigning: (state) => state.embeddedSigning,
      },
      actions: {
        setLastEnvelopeId: async ({ commit }, envelopeId) => {
          commit('SET_LAST_ENVELOPE_ID', envelopeId);
        },
        setEmbeddedSigning: ({ commit }, isEmbed) => commit('SET_EMBEDDED_SIGNING', isEmbed),
      },
    },
  },
  // this is used to persist the userModule data on refreshes and browser closing.
  plugins: [createPersistedState({ paths: ['userModule'] })],
});
