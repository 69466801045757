.
<template>
  <div class="w-full min-h-screen overflow-x-hidden overflow-y-auto bg-white" :class="showSpinner ? 'opacity-25' : ''">
    <div class="flex flex-1 h-screen">
      <main class="flex-1 h-full min-w-0 px-4 pt-4 overflow-x-hidden overflow-y-auto sm:pt-12 2xl:pt-2 lg:px-16 2xl:px-60 lg:flex"><div class="hidden lg:block w-96"></div>
        <section aria-labelledby="primary-heading" class="flex flex-col flex-1 h-full min-w-0 overflow-x-visible pt-14 lg:pt-0 lg:order-last">
          <ParticipantInfo>
            <template v-slot:header>
              <h1 class="px-4 mt-6 text-3xl text-darkBlue lg:mt-16">
                {{ participantInfo.firstName }}, would you like to select a representative?
              </h1>
            </template>

            <template v-slot:description>
              <h3 class="px-4 mt-4 text-base font-normal text-darkBlue md:text-xl">
                A representative is someone that you appoint to support you in your role and assist you with your responsibilities as an employer. It should be someone who you know and trust to carry out your wishes. 
              </h3>
              <h3 class="px-4 mt-4 mb-10 text-base font-normal text-darkBlue md:text-xl">
                A representative can help you with any aspect you choose. When supporting you in your role as a Participant Employer, they do so on your behalf to make sure your responsibilities are handled according to your preferences.
              </h3>
            </template>

            <template v-slot:content>
              <RepresentativeInfoContent :relationshipData="relationsData" :participantData="repInfo" @save="proceedToNext" @back="returnToPrevious" @delete='deleteRepresentative' />
            </template>
          </ParticipantInfo>
        </section>
      </main>
      <AppSideNavMenu :participantDetails="participantInfo" :slide="slideName" :id="enrollmentId"/>
    </div>
  </div>
  <GenesysChat></GenesysChat>

  <!-- Spinner -->

  <div class="fixed flex justify-center my-3 text-gray-500 left-1/2 top-1/2" v-if="showSpinner">
    <svg class="h-8 mr-3 -ml-1 animate-spin w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
</template>

<script>
import ParticipantInfo from 'Views/EnrollmentPresentation/StaticScreens/participant_info.vue';
import RepresentativeInfoContent from 'Views/EnrollmentPresentation/TemplateInstances/representative_info_content.vue';
import enrollmentService from 'Services/backend/enrollments';
import { mapActions, mapGetters } from 'vuex';
import addressUtility from 'Utilities/addressUtility';
import edpService from '../../Enrollments/EnrollmentDetails/service';
import GenesysChat from "@/views/Shared/GenesysChat.vue";

export default {
  name: 'RepresentativeInformation',
  mounted() {
    this.enrollmentId = this.$route.params.enrollmentId;
    this.enrolleeId = this.$route.params.enrolleeId;
    this.enrolleeType = this.$route.params.enrolleeType;
  },
  components: {
    GenesysChat,
    ParticipantInfo,
    RepresentativeInfoContent,
  },
  data() {
    return {
      participantInfo: {
        firstName: '',
        lastName: '',
      },
      repInfo: {
        relationship: '',
        relationshipDescription: '',
        firstName: '',
        lastName: '',
        altNameContact: '',
        phonePrimary: '',
        email: '',
        dateOfBirth: '',
        socialSecurityNumber: '',
        driverLicenseId: '',
        primaryAddress: '',
        addressPrimary: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          zipcode: '',
          county: '',
          state: '',
        },
      },
      enrollmentId: '',
      slideName: 'Representative Information',
      showSpinner: true,
      enrolleeType: '',
      relationsData: [],
    };
  },
  methods: {
    returnToPrevious() {
      if (this.showSpinner) return;
      this.$router.push({ name: 'EORInformation', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
    },

    deleteRepresentative() {
      const body = {
        type: 'DELETE-REPRESENTATIVE',
      };

      enrollmentService.updateEnrolleeDetails(this.enrolleeId, body).then(
        (response) => {
          edpService.processEnrolleePresentationSavedResponse(response, this.$toast.bind(this));
          this.$router.push({ name: 'E-Sign1', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
        },
        (error) => {
          edpService.processEnrolleePresentationSavedResponse(error, this.$toast.bind(this));
          console.error(error);
        },
      );
    },
    proceedToNext(data) {
      if (this.showSpinner) return;
      const body = {
        type: 'REPRESENTATIVE',
        representative: data,
      };

      enrollmentService.updateEnrolleeDetails(this.enrolleeId, body).then(
        (response) => {
          edpService.processEnrolleePresentationSavedResponse(response, this.$toast.bind(this));
          this.$router.push({ name: 'E-Sign1', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
        },
        (error) => {
          edpService.processEnrolleePresentationSavedResponse(error, this.$toast.bind(this));
          console.error(error);
        },
      );
    },
    ...mapActions('enrollmentModule', ['loadEnrollees', 'loadRelations']),
  },

  computed: {
    ...mapGetters('enrollmentModule', ['getEnrollees', 'getRelationships']),
  },

  watch: {
    getEnrollees(newEnrollees) {
      if (newEnrollees) {
        this.showSpinner = false;
        const tempEnrollees = {
          relationship: newEnrollees?.representative?.relationship,
          relationshipDescription: newEnrollees?.representative?.relationshipDescription,
          phonePrimary: newEnrollees?.representative?.phonePrimary,
          email: newEnrollees?.representative?.email,
          dateOfBirth: newEnrollees?.representative?.dateOfBirth,
          socialSecurityNumber: newEnrollees?.representative?.socialSecurityNumber,
          driverLicenseId: newEnrollees?.representative?.driverLicenseId,
          primaryAddress: addressUtility.addressToString(newEnrollees?.representative?.address),
          firstName: newEnrollees?.representative?.firstName,
          lastName: newEnrollees?.representative?.lastName,
          altNameContact: newEnrollees?.representative?.altNameContact,
          addressPrimary: {
            addressLine1: newEnrollees?.representative?.address?.addressLine1,
            addressLine2: newEnrollees?.representative?.address?.addressLine2,
            city: newEnrollees?.representative?.address?.city,
            zipcode: newEnrollees?.representative?.address?.zipcode,
            county: newEnrollees?.representative?.address?.county,
            state: newEnrollees?.representative?.address?.stateAbbrev,
          },
        };

        const validAddr = newEnrollees?.representative?.address;
        tempEnrollees.primaryAddress = addressUtility.addressToString(validAddr);

        this.repInfo = tempEnrollees;
        this.participantInfo.firstName = newEnrollees.participant.firstName;
        this.participantInfo.lastName = newEnrollees.participant.lastName;
        this.participantInfo.altNameContact = newEnrollees.participant.altNameContact;
      }
    },
    getRelationships(participantRelationship) {
      if (participantRelationship) {
        this.relationsData = participantRelationship;
      }
    },
  },

  async created() {
    this.enrolleeId = this.$route.params.enrolleeId;
    await this.loadEnrollees(this.$route.params.enrolleeId);
    await this.loadRelations();
  },
};
</script>

<style></style>
