.
<template>
  <div class="w-full min-h-screen overflow-x-hidden bg-gtIpadBackground" :class="showSpinner ? 'opacity-25' : ''">
    <div class="flex flex-1 h-screen">
      <main class="flex-1 h-full min-w-0 px-4 pt-4 overflow-x-hidden overflow-y-auto sm:pt-12 2xl:pt-2 lg:px-16 2xl:px-60 lg:flex"><div class="hidden lg:block w-96"></div>
        <section aria-labelledby="primary-heading" class="flex flex-col flex-1 h-full min-w-0 overflow-x-visible pt-14 lg:pt-0 lg:order-last">
          <ParticipantInfo>
            <template v-slot:header>
              <h1 class="px-4 mt-6 text-3xl text-darkBlue lg:mt-16">
                First, tell us a little about yourself.
              </h1>            
            </template>

            <template v-slot:description>
              <h3 class="px-4 mt-4 mb-10 text-base font-normal text-darkBlue md:text-xl">
                Please make sure that all of this information is both complete and accurate.
              </h3>
            </template>

            <template v-slot:content>
              <VendorInfo2Content :participantData="vendorInfo2" @save="proceedToNext" @back="returnToPrevious" />
            </template>
          </ParticipantInfo>
        </section>
      </main>
      <AppSideNavMenu :participantDetails="vendorInfo2" :slide="slideName" :id="enrollmentId"/>
    </div>
  </div>
  <GenesysChat></GenesysChat>

  <!-- Spinner -->
  <div class="fixed flex justify-center my-3 text-gray-500 left-1/2 top-1/2" v-if="showSpinner">
    <svg class="h-8 mr-3 -ml-1 animate-spin w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
</template>

<script>
import ParticipantInfo from 'Views/EnrollmentPresentation/StaticScreens/participant_info.vue';
import VendorInfo2Content from 'Views/EnrollmentPresentation/TemplateInstances/vendor_info2_content.vue';
import { mapActions, mapGetters } from 'vuex';
import enrollmentService from 'Services/backend/enrollments';
import addressUtility from 'Utilities/addressUtility';
import edpService from '../../Enrollments/EnrollmentDetails/service';
import GenesysChat from "@/views/Shared/GenesysChat.vue";

export default {
  name: 'VendorInformation2',
  mounted() {
    this.enrollmentId = this.$route.params.enrollmentId;
    this.enrolleeId = this.$route.params.enrolleeId;
    this.enrolleeType = this.$route.params.enrolleeType;
  },
  components: {
    GenesysChat,
    ParticipantInfo,
    VendorInfo2Content,
  },
  data() {
    return {
      enrollmentId: '',
      enrolleeId: '',
      vendorInfo2: {
        step: 2,
        addressPrimary: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          zipcode: '',
          county: '',
          state: '',
        },
        addressSecondary: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          zipcode: '',
          county: '',
          state: '',
        },
        primaryContactPerson: '',
        primaryContactPersonJobTitle: '',
        firstName: '',
        lastName: '',
      },
      slideName: 'Vendor Information',
      showSpinner: true,
    };
  },
  methods: {
    proceedToNext(data) {
      if (this.showSpinner) return;
      if (!data?.addressSecondary?.addressLine1) delete data.addressSecondary;
      const body = {
        type: 'VENDOR',
        vendor: data,
      };

      enrollmentService.updateEnrolleeDetails(this.enrolleeId, body).then(
        (response) => {
          edpService.processEnrolleeSavedResponse(response, this.$toast.bind(this));
          this.$router.push({ name: 'E-Sign1', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
        },
        (error) => {
          console.error(error);
        },
      );
    },

    returnToPrevious() {
      if (this.showSpinner) return;
      this.$router.push({ name: 'VendorInformation1', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
    },
    ...mapActions('enrollmentModule', ['loadEnrollees']),
  },

  computed: {
    ...mapGetters('enrollmentModule', ['getEnrollees']),
  },
  watch: {
    getEnrollees(newEnrollees) {
      if (newEnrollees) {
        this.showSpinner = false;
        const enrollee = newEnrollees?.participant || newEnrollees?.caregiver || newEnrollees?.vendor;
        const tempEnrollees = {
          step: 2,
          firstName: enrollee?.firstName,
          lastName: enrollee?.lastName,
          primaryContactPerson: enrollee?.primaryContactPerson,
          primaryContactPersonJobTitle: enrollee?.primaryContactPersonJobTitle,
          primaryAddress: addressUtility.addressToString(enrollee?.addressPrimary),
          alternateAddress: addressUtility.addressToString(enrollee?.addressSecondary),
          addressPrimary: {
            addressLine1: enrollee?.addressPrimary?.addressLine1,
            addressLine2: enrollee?.addressPrimary?.addressLine2,
            city: enrollee?.addressPrimary?.city,
            zipcode: enrollee?.addressPrimary?.zipcode,
            county: enrollee?.addressPrimary?.county,
            state: enrollee?.addressPrimary?.stateAbbrev,
          },
          addressSecondary: {
            addressLine1: enrollee?.addressSecondary?.addressLine1,
            addressLine2: enrollee?.addressSecondary?.addressLine2,
            city: enrollee?.addressSecondary?.city,
            zipcode: enrollee?.addressSecondary?.zipcode,
            county: enrollee?.addressSecondary?.county,
            state: enrollee?.addressSecondary?.stateAbbrev,
          },
        };

        this.vendorInfo2 = Object.assign({}, this.vendorInfo2, tempEnrollees);
      }
    },
  },

  async created() {
    this.enrolleeId = this.$route.params.enrolleeId;
    await this.loadEnrollees(this.$route.params.enrolleeId);
  },
};
</script>

<style></style>
