import { store } from '@/store';
import {axios, API} from '@/api'

export default {
  samlLogin() {
    return new Promise((resolve, reject) => {
      axios.get(API.samlLogin())
      .then((response) => {
        return resolve(response.data);
      },
      (error) => {
        return reject(error);
      });
    });
  },
  async logout() {
    try {
      const response = await axios.get(API.logout())
      this.clearState();
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  },
  async userCheck() {
    return new Promise((resolve, reject) => {
      axios.get(API.userCheck())
      .then((response) => {
        //set state of login to true.
        store.commit("setLogin", true);
        return resolve(response.data);
      },
      (error) => {
        return reject(error);
      });
    });
  },
  async verifyEnrolleeDetails(params) {
    return new Promise((resolve, reject) => {
      axios.post(API.verifyEnrolleeDetails(), params)
      .then((response) => {
        return resolve(response.data.data);
      },
      (error) => {
        return reject(error);
      })
    })
  },
  clearState() {
    store.commit('resetUserState')
    store.commit("setLogin", false);
    store.commit('setUserInfo', '');
    store.commit('setUserId', '');
    store.commit('setRoleId', 0);
    localStorage.removeItem('pathToLoadAfterLogin')
    
  }

}
