<template>
  <div id="genesys-container">

  </div>
</template>
<script>
import {ENV, GENESYS_API_KEY} from "@/config";

export default {
  methods: {
    attachGenesysChat(g, e, n, es, ys) {
      if (!g['_genesysJs']) {
        g['_genesysJs'] = e;
        g[e] = g[e] || function () {
          (g[e].q = g[e].q || []).push(arguments)
        };
        g[e].t = 1 * new Date();
        g[e].c = es;
        ys = document.createElement('script');
        ys.async = 1;
        ys.src = n;
        ys.charset = 'utf-8';
        document.getElementById('genesys-container').appendChild(ys);
      } else {
        document.getElementsByClassName('cx-messenger-1lmam3v').display = 'initial';
      }
    }
  },
  mounted() {
    if (ENV !== 'production') {
      this.attachGenesysChat(window, 'Genesys', 'https://apps.mypurecloud.com/genesys-bootstrap/genesys.min.js', {
        environment: 'prod',
        deploymentId: GENESYS_API_KEY
      });
    }
  }
}
</script>
