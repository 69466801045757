import {axios, API} from '@/api'
import enrollments from './enrollments'

export default {
  async getRoles() {
    try {
      const response = await axios.get(API.getRoles(), { headers: enrollments.getAuthHeaders() })
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  },
}
